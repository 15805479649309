import moment from "moment";

/**
 * Subtract one month by considering exact (28/29/30/31) days of relevant months.
 * @constructor
 * @param {Object} date - Date (moment date object) from which one month will be subtracted.
 * @remarks
 * - This function subtract 28/29/30/31 days considering the provided date's month length, if the provided date is not the first date of its month
 * - And it subtracts exact previous month days, if the provided date is the first date of its month
 * - And it returns the last date of the previous month, if the provided date is the last date of its month
 * - Case 1: 1 month subtraction from 15th March 2024 -> 15th February 2024
 * - Case 2: 1 month subtraction from 1st March 2024 -> 1st February 2024
 * - Case 3: 1 month subtraction from 31st March 2024 -> 29th February 2024 and 31st January 2024 -> 31st December 2023
 */
export const subtractExactOneMonth = (date) => {
    let yearOfTheDate = date.year()
    let isLeapYear = yearOfTheDate % 4 === 0
    let dateOfTheDate = date.date()
    let monthOfTheDate = date.month()
    const monthJson = {
        0: 31, // Jan
        1: isLeapYear? 29:28, // Feb
        2: 31,  // March
        3: 30,  // April
        4: 31,  // May
        5: 30,  // June
        6: 31,  // July
        7: 31,  // August
        8: 30,  // Sept
        9: 31,  // Oct
        10: 30,  // Nov
        11: 31  // Dec
    }
    let daysNeededToBeSubtracted = 0
    let previousMonth = monthOfTheDate - 1
    let previousMonthsDays = previousMonth >= 0? monthJson[previousMonth]:monthJson[11]
    // About above process of accessing days of the previous month from monthJson,
    // If the previousMonth is -1 or less the formula for getting the month serial is 12 + previousMonth
    // But in case of subtracting one month, we will only get -1 as value, hence we are accessing the month at serial 11
    let currentMonthsDays = monthJson[monthOfTheDate]
    if (dateOfTheDate === 1) {
        // Dealing with the case, if the date is the first day of the month
        daysNeededToBeSubtracted = previousMonthsDays
    } else if (dateOfTheDate === currentMonthsDays) {
        // Dealing with the case, if the date is the last day of the month
        if (previousMonth >= 0) {
            return moment(yearOfTheDate + "-" + (previousMonth + 1) + "-" + previousMonthsDays, "YYYY-MM-DD")
        } else {
            // Returning previous year's december's last date, as there will always be -1
            return moment((yearOfTheDate - 1) + "-12-" + previousMonthsDays, "YYYY-MM-DD")
        }
    } else {
        daysNeededToBeSubtracted = (previousMonthsDays - dateOfTheDate) + dateOfTheDate
    }
    return moment(date).subtract(daysNeededToBeSubtracted, 'days');
}