import React, {useMemo, useRef, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Form} from "react-bootstrap";
import {SolInput} from "../../../../app/components";
import {useForm} from "react-hook-form";
import SearchFieldForMobile from "../../../../app/features/search/views/searchFieldComponents/searchFieldForMobile";
import NotificationsForMobile from "../../../../app/features/notifications/views/notifications/notificationsForMobile";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import moment from "moment/moment";

export function HeaderMobile() {
  const [topBarWidth, setTopBarWidth] = useState(0)
  const uiService = useHtmlClassService();
  const history = useHistory();
  const topBarRef = useRef(null)

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);

  const logoutClick = () => {
    history.push("/logout");
  };

  useEffect(() => {
    if (topBarRef) {
      setTopBarWidth(topBarRef.current.clientWidth)
    }
  }, [topBarRef])

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            ref={topBarRef}
            {...layoutProps.headerMobileAttributes}
        >
          {layoutProps.headerMenuSelfDisplay && (
              <>
                {/*begin::Header Menu Mobile Toggle*/}
                <button className="btn p-0 burger-icon ml-4" id="kt_aside_mobile_toggle">
                  <span/>
                </button>
                {/*end::Header Menu Mobile Toggle*/}
              </>
          )}
          {/* TODO: Above is copied from the following <div> section (after Link) and it's id is updated to open menu when it is clicked - Noor Reza, December 6, 2022*/}
          {/*begin::Logo*/}
          <Link to="/">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link>
          {/*end::Logo*/}
          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {/*{layoutProps.asideDisplay && (*/}
            {/*    <>*/}
            {/*      /!*begin::Aside Mobile Toggle*!/*/}
            {/*      <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">*/}
            {/*        <span/>*/}
            {/*      </button>*/}
            {/*      /!*end::Aside Mobile Toggle*!/*/}
            {/*    </>*/}
            {/*)}*/}

            {/*{layoutProps.headerMenuSelfDisplay && (*/}
            {/*    <>*/}
            {/*      /!*begin::Header Menu Mobile Toggle*!/*/}
            {/*      <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">*/}
            {/*        <span/>*/}
            {/*      </button>*/}
            {/*      /!*end::Header Menu Mobile Toggle*!/*/}
            {/*    </>*/}
            {/*)}*/}
            {/* begin: Search */}
            {/*<IconButton>*/}
            {/*  <SearchIcon style={{ color: 'white' }}/>*/}
            {/*</IconButton>*/}
            <SearchFieldForMobile/>
            <NotificationsForMobile parentWidth={topBarWidth}/>
            {/* end: Search */}

            {/*begin::Topbar Mobile Toggle*/}
            {/*TODO: Following had a class 'kt_header_mobile_topbar_toggle', which is removed*/}
            <button
                className="btn btn-hover-text-primary p-0 ml-2"
                onClick={logoutClick}
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")} />
              </span>
            </button>
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
