import React, { useEffect, useState } from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import { FilterButton, Toolbar } from "../../../../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DataTable, DataTableContainer, FilterInput, FilterReactSelect } from "../../../../components";
import { transactionActions } from "../../actions";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import { LoadingSpinner } from '../../../../components'
import { connect } from "react-redux";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import { Button, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { theme, useStylesForDatePicker } from "../../../../utils/muiPickerCustomStyle";
import moment from "moment";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import { DayPickerRangeController } from "react-dates";
import { SolTypography } from '../../../../components';
import { useSubheader } from "../../../../../_metronic/layout";
import { checkAuthorization } from "../../../auth/authorization";
import { getServiceName } from "../../../../utils/roleRelatedValues/serviceNames";
import { getFeatureName } from "../../../../utils/roleRelatedValues/featureNames";
import { getActionName } from "../../../../utils/roleRelatedValues/actionNames";
import { isNumber } from '../../../../utils/utilityFunctions';
import {toBengaliNumber} from "bengali-number";
import {exportInExcel} from "../../../../utils/excelExport";
import {Link} from "react-router-dom";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import ListGroup from "react-bootstrap/ListGroup";


const TransactionSettlements = props => {
    const classes = useStylesForDatePicker();
    const { clearErrors, setValue, reset, handleSubmit, control, errors, register } = useForm()
    const subheader = useSubheader();
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState("EN");
    const [showRentBreakDownModal, setShowRentBreakDownModal] = useState(false);
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [garages, setGarages] = useState(undefined);
    const [payments, setpayments] = useState([]);
    const [allPaymentsList, setAllPaymentsList] = useState([])
    const [borrowers, setBorrowers] = useState([]);
    const [borrower, setBorrower] = useState('');
    const [fileUploadModal, setFileUploadModal] = useState(false);
    const [filter, setFilter] = useState(null);

    // Rent Break down
    const [transactionID, setTransactionID] = useState("");
    const [payment, setPayment] = useState("");
    const [platformFee, setPlatformFee] = useState("");
    const [mfsCharges, setMFSCharges] = useState("");
    const [receivableByMFG, setReceivableByMFG] = useState("");

    const [transactionServiceName, setTransactionServiceName] = useState(getServiceName('transactionService'));
    const [settlementFeatureName, setSettlementFeatureName] = useState(getFeatureName('settlementFeature'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(null);
    const [perPage, setPerPage] = useState(null);


    const transactionColumn = [
        {
            field: 'payment_id',
            title: "ID",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },

        },
        {
            field: 'rent_details',
            title: "Smart Battery",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle className={"mb-5"}>{rowdata.rent_details?.ev_details?.device_sl || "-"}</SolTypography.SubTitle></b>
                    {/* <SolTypography.SubTitle>{rowdata.sender}</SolTypography.SubTitle>
                    <SolTypography.Text secondary>{rowdata.garage_name}</SolTypography.Text> */}
                </>
            }

        },
        {
            field: 'created_by_name',
            title: "From",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle className={"mb-5"} style={{ whiteSpace: 'nowrap' }}>{rowdata.created_by_name || "-" }</SolTypography.SubTitle></b>
                    <SolTypography.SubTitle secondary style={{ whiteSpace: 'nowrap' }}>{rowdata.garageName || "-" }</SolTypography.SubTitle>
                    <SolTypography.Text secondary style={{ whiteSpace: 'nowrap' }}>{rowdata.garage_phone_number || "-" }</SolTypography.Text>
                </>
            }


        },
        {
            field: 'created_at',
            title: "Rent Period",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowdata) => {
                return <span style={{
                    whiteSpace: "nowrap"
                }}>{rowdata.rent_details?.start_time ? new DateTimeFormat(rowdata.rent_details?.start_time).localDisplayTime : ''} - {rowdata.rent_details?.expire_time ? new DateTimeFormat(rowdata.rent_details?.expire_time).localDisplayTime : ''}</span>
            }
        },
        {
            field: 'driver_name',
            title: "Driver",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            emptyValue: () => {
                return "-"
            }
        },
        {
            field: 'pay_amount',
            title: "Paid by Garage/Dealer (Tk)",
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
            render: (rowData) => {
                return <>
                    <h4>{new Intl.NumberFormat('en-IN').format(rowData.pay_amount)}</h4>
                </>
            }
        },
        {
            field: 'mfg_receivable_amount',
            title: "Receivable by Battery MFG (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return <>
                    {rowData.mfg_receivable_amount || rowData.mfg_receivable_amount === 0? <>
                        {/* TODO: Need to use something instead of Link (customized css?), as 'to' is a mandatory prop for Link*/}
                        <Link style={{color: "#F18D00"}} onClick={() => showRentBreakDown(rowData)}>
                            <h4>{new Intl.NumberFormat('en-IN').format(rowData.mfg_receivable_amount)}</h4>
                        </Link>
                    </>:'-'}
                </>
            }
        },
        {
            field: 'solshare_ref',
            title: "SOlshare Ref",
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: () => {
                return "-"
            }
        }
    ]
    const payment_type = {
        payment_type: "deduction",
        is_pay_amount: "valid"
    }

    const {
        FETCH_PAYMENTS,
        COLLECT_BORROWERS,
        GET_GARAGE_LIST,
        FETCH_BATTERY_SETTLEMENT_PAYMENTS,
        FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props



    const showFileUploadModal = () => {
        // setFileUploadModal(true);
        // props.history.push('/payments/settlements/upload')
        props.history.push("/payments/settlements/upload");
    }

    const paymentsListWihtoutPagination = () => {
        let payload = { ...filter }
        // delete payload['page']
        props.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT({...payload, ...payment_type});
    }


    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        if (checkAuthorization(props.authorization, transactionServiceName, settlementFeatureName, [updateActionName])) {

            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={showFileUploadModal} classes={classes} >
                        <i className="fa fa-random" />{props.language === 'EN' ? 'Upload Transactions' : 'সিরিয়াল নাম্বার তৈরি'}
                    </Button>
                </div>)
        } else {
            subheader.setActionButtons(null)
        }
        subheader.setBreadCrumbComponent(null)
        FETCH_BATTERY_SETTLEMENT_PAYMENTS({page: 1, ...payment_type})
        COLLECT_BORROWERS()
        GET_GARAGE_LIST()
        return () => {
            subheader.setActionButtons(null)
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    // Saving latest garage information
    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            setGarages(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        }
    }, [props.garages])

    useEffect(() => {
        if (props.settlementPayments) {
            const temps = props.settlementPayments.results;

            if (Array.isArray(temps)) {
                setpayments(temps.map((item, index) => {
                    let garageName = item.garage_name
                    if (garages && garages.length > -1) {
                        let filteredData = garages.filter(garage => garage.pk === item.garage_guid)
                        if (filteredData.length > 0) {
                            garageName = filteredData[0].name
                        }
                    }

                    return {
                        ...item,
                        garageName: garageName
                    }
                }))

                setCount(props.settlementPayments?.count);
                setPerPage(props.settlementPayments?.page_size);
            } 
            
            else if (Array.isArray(props.settlementPayments)) {

                setAllPaymentsList(props.settlementPayments.map((item, index) => {
                    return {
                        ...item,
                    }
                }))
            }

        }
    }, [props.settlementPayments, garages]);

    useEffect(() => {
        if (props.settlementPaymentsForExport) {
            const transactions = props.settlementPaymentsForExport
            let presentTime = moment()
            let transactionsLength = transactions.length
            let excelRows = [
                ["ID", "Smart Battery ID", "Payment From", "Payment By", "Garage Name", "Garage Phone Number",
                    "Rent Started At", "Rent Ended At", "Driver Name", "From Garage", "Paid by Garage", "Platform Fee",
                    "MFS Charge", "Receivable by MFG", "SOLshare Ref"]
            ]
            for (let i=0; i<transactions.length; i++) {
                excelRows.push(
                    [
                        transactions[i].payment_id?.trim()?.replace(',', ''),
                        transactions[i].rent_details?.ev_details?.device_sl?.trim()?.replace(',', '') || "",
                        transactions[i].created_by?.trim()?.replace(',', ''),
                        transactions[i].created_by_name?.trim()?.replace(',', ''),
                        transactions[i].garage_name?.replace(',', ''),
                        transactions[i].garage_phone_number?.replace(',', ''),
                        transactions[i].rent_details?.start_time?.trim()?.replace(',', ''),
                        transactions[i].rent_details?.expire_time?.trim()?.replace(',', ''),
                        transactions[i].driver_name?.trim()?.replace(',', ''),
                        transactions[i].garage_name?.trim()?.replace(',', ''),
                        String(isNumber(transactions[i].pay_amount) ? transactions[i].pay_amount:'')?.trim()?.replace(',', ''),
                        String( isNumber(transactions[i].platform_fee) ? transactions[i].platform_fee : ''  )?.trim()?.replace(',', ''),
                        String( isNumber(transactions[i].mfs_charge_amount) ? transactions[i].mfs_charge_amount : ''  )?.trim()?.replace(',', ''),
                        String(isNumber(transactions[i].mfg_receivable_amount) ? transactions[i].mfg_receivable_amount : '')?.trim()?.replace(',', ''),
                        transactions[i].solshare_ref?.trim()?.replace('#', '')?.replace(',', '')
                    ])
            }
            let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
            transactionsLength > 0? excelRows.push(
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
            ):excelRows.push(["", "", "", "", "", "", "", noDataAvailableText, "", "", "", "", "", "", ""])
            let fileName = 'battery_rent_settlements_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.settlementPaymentsForExport]);

    useEffect(() => {
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowers = props.borrowers
            setBorrowers(borrowers.map((item) => {
                let label = item.name
                let garageName = item.garage_name
                if (garages && garages.length > -1) {
                    let filteredData = garages.filter(garage => garage.pk === item.garage_guids[0])
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                label += ' (' + garageName + ')'

                if (borrower && borrower.value === item.garage_guids[0]) {
                    let selectedBorrower = borrower
                    selectedBorrower['label'] = label
                    selectedBorrower['value'] = item.garage_guids[0]
                    setValue('borrower', selectedBorrower)
                    setBorrower(selectedBorrower)
                }

                return {
                    ...item,
                    label: label,
                    value: item.garage_guids[0]
                }
            }))
        }
    }, [props.borrowers, garages])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    const resetFilterValues = () => {
        setStartDate(null)
        setEndDate(null)
        setBorrower('')
        reset({
            "garage_phone_number": "",
            "borrower": "",
            "is_settled": ""
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterApplied) {
            FETCH_BATTERY_SETTLEMENT_PAYMENTS({page: newPage, ...payment_type, ...filter})
        } else {
            FETCH_BATTERY_SETTLEMENT_PAYMENTS({page: newPage, ...payment_type})
        }
        GET_GARAGE_LIST()
    }

    const filterTheList = (data) => {
        let filter = {}
        if (data.borrower) {
            filter['garage_guid'] = data.borrower.value
        }
        if (data.is_settled) {
            filter['is_settled'] = data.is_settled.value
        }
        if (data.garage_phone_number) {
            filter['garage_phone_number'] = data.garage_phone_number
        }
        if (start && end) {
            filter['created_at_from'] = start.format('YYYY-MM-DD')
            filter['created_at_to'] = end.format('YYYY-MM-DD')
        }
        if (Object.keys(filter).length > 0) {
            setPage(1)
            setFilter(filter)
            setFilterApplied(true)
            FETCH_BATTERY_SETTLEMENT_PAYMENTS({ ...payment_type ,...filter, page: 1 })
            GET_GARAGE_LIST()
        }
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the approved transactions list
            setPage(1)
            setFilter(null)
            FETCH_BATTERY_SETTLEMENT_PAYMENTS({ page: 1, ...payment_type})
            GET_GARAGE_LIST()
        }
        resetFilterValues()
        setFilterApplied(false)
        clearErrors()
    }

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const FilterForm = <>
        <h4><SolTypography.SubTitle >Filter</SolTypography.SubTitle></h4>
        <br/>

        <div className={'row'}>
            <div className={'col-md-12'}>
                <Form onSubmit={handleSubmit(filterTheList)}>
                    <div className={'row g-6'}>


                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Garage Phone Number</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'garage_phone_number'}
                                    type="text"
                                    placeholder={'Type Mobile Number'}
                                    ref={register()}
                                />
                                {errors.garage_phone_number ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage_phone_number.message}</div></> : null}

                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Transaction Date</SolTypography.Text></Form.Label>
                                </div>
                                <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                    overlay={popover}>
                                    <FilterInput
                                        name={'dateRangePickerInput'}
                                        readOnly={true}
                                        value={dateRange}
                                        placeholder={"Select a date range"}
                                    />
                                </OverlayTrigger>
                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Borrower</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"borrower"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value, defaultValue, name, ref },
                                        { invalid, isTouched, isDirty }) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Select a borrower"}
                                            isDisabled={props.collectingBorrowers}
                                            isLoading={props.collectingBorrowers}
                                            defaultValue={defaultValue}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={borrowers}
                                            isSearchable={true}
                                            onChange={(selected, { action }) => {
                                                if (action === "clear") {
                                                    setBorrower('')
                                                }
                                                onChange(selected)
                                                if (selected) {
                                                    setBorrower(selected)
                                                }
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {errors.borrower ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.borrower.message}</div></> : null}
                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Transaction Type</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"is_settled"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value,defaultValue, name, ref },
                                        { invalid, isTouched, isDirty }) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Transaction Type"}
                                            maxMenuHeight={200}
                                            value={value}
                                            defaultValue={defaultValue}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={[
                                                { label: "Settled Transactions", value: "true" },
                                                { label: "Unsettled Transactions", value: "false"},
                                            ]}
                                            isSearchable={true}
                                            onChange={(selected, { action }) => {
                                                onChange(selected)
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {errors.is_settled ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.is_settled.message}</div></> : null}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12 no-padding"}>

                            <FilterButton variant="warning" size={'sm'} type="submit"
                                disabled={props.listLoading}
                                className={'ml-3'}>
                                Apply Filter
                            </FilterButton>

                            &nbsp;
                            &nbsp;

                            <FilterButton variant="outline-dark" size={'sm'} onClick={() => { resetFilter() }}>
                                Reset Filter
                            </FilterButton>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <hr /></>

    const showRentBreakDown = (data) => {
        setTransactionID(data.payment_id)
        setPayment(data.pay_amount)
        setPlatformFee(data.platform_fee)
        setMFSCharges(data.mfs_charge_amount)
        setReceivableByMFG(data.mfg_receivable_amount)
        setShowRentBreakDownModal(true)
    }

    const hideRentBreakDownModal = () => {
        setShowRentBreakDownModal(false)
    }

    const renderRentBreakdownModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideRentBreakDownModal}
                centered={true}
                modal={showRentBreakDownModal}
                title={<>{'Rent Breakdown - Transaction #' + transactionID}</>}>
                <div className={'row'}>
                    <div className={'col-lg-12'}>
                        <ListGroup>
                            <ListGroup.Item style={{color: '#8C8C9B'}}>
                                <span className={'float-left'}><b>Particulars</b></span><span className={'float-right'}>Amount (Tk)</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {/* There is no possibility of payment being zero */}
                                <span className={'float-left'}><b>Payment Made by Garage/Dealer</b></span><span className={'float-right'}><h4><strong>{payment? new Intl.NumberFormat('en-IN').format(payment):'-'}</strong></h4></span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>SOLshare Platform Fee</b></span><span className={'float-right'}><h4><strong>{platformFee || platformFee === 0? new Intl.NumberFormat('en-IN').format(platformFee):'-'}</strong></h4></span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Mobile Financial Services Charge</b></span><span className={'float-right'}><h4><strong>{mfsCharges || platformFee === 0? new Intl.NumberFormat('en-IN').format(mfsCharges):'-'}</strong></h4></span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Receivable by Battery MFG</b></span><span className={'float-right'}><h4><strong>{receivableByMFG || receivableByMFG === 0? new Intl.NumberFormat('en-IN').format(receivableByMFG):'-'}</strong></h4></span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Mobile Application Events"} showBackButton={false} showCardHeader={false}>


            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>Battery Rent Settlements</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton
                                onClick={paymentsListWihtoutPagination}
                                disabled={props.collectingTransactionsForExport}>
                                {props.collectingTransactionsForExport? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>:
                                    <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download" />}
                            </IconButton>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large" />
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>

                <hr />
            </div>


            {showFilter ?
                FilterForm : null}
            <div>

                {/* <div className={'row'}>
                    <div className={'col-lg-6'}><h1><b>Total transactions</b>&nbsp;{totalPayments}</h1></div>
                    <div className={'col-lg-6'}><h1><b>Total payments</b>&nbsp;{totalAmounts}</h1></div>
                </div> */}
                <DataTableContainer>
                    {props.listLoading !== true ? <DataTable
                        columns={transactionColumn}
                        isLoading={props.listLoading}
                        data={payments}
                        asyncPagination={true}
                        count={count}
                        itemsPerPage={perPage}
                        onChangePage={onChangePage}
                        page={page}
                    /> : <LoadingSpinner language={language} loadingSubText={"Fetching transactions list..."} />}
                </DataTableContainer>

            </div>

            {renderRentBreakdownModal()}

        </ContentWrapper>
    );
};

TransactionSettlements.propTypes = {

};

export default connect((state) => {
    return {
        authorization: state.auth.authorizations,
        language: state.auth.language,
        isLoading: state.transactionReducer.isLoading,
        listLoading: state.transactionReducer.listLoading,
        settlementPayments: state.transactionReducer.settlementPayments,
        collectingTransactionsForExport: state.transactionReducer.collectingTransactionsForExport,
        settlementPaymentsForExport: state.transactionReducer.settlementPaymentsForExport,
        borrowers: state.commonReducer.borrowers,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        garages: state.commonReducer.garages
    }
}, { ...transactionActions, ...commonActions })(TransactionSettlements);
