import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE: ()=>({
        [CALL_API]: {
            endpoint: '/dashboard_summary',
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE,
            ]
        }
    }),

    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/dashboard_summary', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE,
            ]
        }
    }),

    COLLECT_ZONE_AREA_OF_A_FINANCIER: (orgGuid)=>({
        [CALL_API]: {
            endpoint: '/organization/zone/' + orgGuid,
            method: 'GET',
            types: [
                actionTypes.COLLECT_ZONE_AREA_OF_A_FINANCIER_REQUEST,
                actionTypes.COLLECT_ZONE_AREA_OF_A_FINANCIER_SUCCESS,
                actionTypes.COLLECT_ZONE_AREA_OF_A_FINANCIER_FAILURE
            ]
        }
    }),

    COLLECT_AT_A_GLANCE_INFO: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/overall-analytics-summary', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_AT_A_GLANCE_INFO_REQUEST,
                actionTypes.COLLECT_AT_A_GLANCE_INFO_SUCCESS,
                actionTypes.COLLECT_AT_A_GLANCE_INFO_FAILURE,
            ]
        }
    }),

    COLLECT_BRANCH_WISE_PERFORMANCE_INFO: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/branch-wise-summary', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_BRANCH_WISE_PERFORMANCE_INFO_REQUEST,
                actionTypes.COLLECT_BRANCH_WISE_PERFORMANCE_INFO_SUCCESS,
                actionTypes.COLLECT_BRANCH_WISE_PERFORMANCE_INFO_FAILURE,
            ]
        }
    }),

    COLLECT_LOW_PERFORMING_BORROWERS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/borrower-wise-summary', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_LOW_PERFORMING_BORROWERS_REQUEST,
                actionTypes.COLLECT_LOW_PERFORMING_BORROWERS_SUCCESS,
                actionTypes.COLLECT_LOW_PERFORMING_BORROWERS_FAILURE,
            ]
        }
    }),

    COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/activity-report', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_REQUEST,
                actionTypes.COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_SUCCESS,
                actionTypes.COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_FAILURE,
            ]
        }
    }),

    COLLECT_INACTIVE_BATTERIES: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/devices/inactive', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_INACTIVE_BATTERIES_REQUEST,
                actionTypes.COLLECT_INACTIVE_BATTERIES_SUCCESS,
                actionTypes.COLLECT_INACTIVE_BATTERIES_FAILURE
            ]
        }
    }),

    COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY: (deviceSerial, filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/rent/active_days_summary/' + deviceSerial, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_REQUEST,
                actionTypes.COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_SUCCESS,
                actionTypes.COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
