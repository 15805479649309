import React, {useEffect, useState, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {useMediaQuery} from "react-responsive";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import CardActions from "@mui/material/CardActions";
import SouthIcon from '@mui/icons-material/South';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {Form, Button} from "react-bootstrap";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import Plot from "react-plotly.js";
import Select from "react-select";


const AtAGlanceOverview = ({data, dataForStaticCards, ...props}) => {
    // Card related values
    const [portfolioSize, setPortfolioSize] = useState(undefined)

    const [portfolioAtRisk, setPortfolioAtRisk] = useState(undefined)
    const [portfolioAtRiskPercentage, setPortfolioAtRiskPercentage] = useState(undefined)

    const [totalOverdue, setTotalOverdue] = useState(undefined)
    const [totalOverduePercentage, setTotalOverduePercentage] = useState(undefined)

    const [totalCollected, setTotalCollected] = useState(undefined)
    const [totalCollectionPercentage, setTotalCollectionPercentage] = useState(undefined)

    const [totalBorrower, setTotalBorrower] = useState(undefined)

    const [deployedThisMonth, setDeployedThisMonth] = useState(undefined)
    const [deployedPreviousMonth, setDeployedPreviousMonth] = useState(undefined)
    const [deploymentPercentage, setDeploymentPercentage] = useState(undefined)

    const [totalDeployedBatteries, setTotalDeployedBatteries] = useState(undefined)

    const [co2EmissionSaved, setCo2EmissionSaved] = useState(undefined)

    // Graph related values
    const projectionTrendGraphTypeOptions = [
        {'label':'Daily', 'value':'daily'},
        {'label':'Monthly', 'value':'monthly'}
    ]
    const [projectionTrendGraphType, setProjectionTrendGraphType] = useState(projectionTrendGraphTypeOptions[1])
    const [graphTypeForTheComponent, setGraphTypeForTheComponent] = useState(projectionTrendGraphTypeOptions[1].value)
    // Monthly
    const [xAxisForMonthlyPaidGraph, setXAxisForMonthlyPaidGraph] = useState(undefined) // Sample data format: ['30 MAR 2023', '31 MAR 2023', '1 APR 2023', '1 MAY 2023', '2 MAY 2023', '3 MAY 2023']
    const [yAxisForMonthlyPaidGraph, setYAxisForMonthlyPaidGraph] = useState(undefined) // Sample data format: [60000, 150000, 255000, 280000, 255000, 350000, 90000, 150000, 270000, 300000, 350000]

    const [xAxisForMonthlyOverdueGraph, setXAxisForMonthlyOverdueGraph] = useState(undefined) // Sample data format: ['30 MAR 2023', '31 MAR 2023', '1 APR 2023', '1 MAY 2023', '2 MAY 2023', '3 MAY 2023']
    const [yAxisForMonthlyOverdueGraph, setYAxisForMonthlyOverdueGraph] = useState(undefined) // Sample data format: [135000, 100000, 100000, 100000, 80000, 50000, 105000, 100000, 100000, 100000, 50000]

    // Daily
    const [xAxisForDailyPaidGraph, setXAxisForDailyPaidGraph] = useState(undefined) // Sample data format: ['30 MAR 2023', '31 MAR 2023', '1 APR 2023', '1 MAY 2023', '2 MAY 2023', '3 MAY 2023']
    const [yAxisForDailyPaidGraph, setYAxisForDailyPaidGraph] = useState(undefined) // Sample data format: [600, 150, 255, 280, 255, 350]

    const [xAxisForDailyOverdueGraph, setXAxisForDailyOverdueGraph] = useState(undefined) // Sample data format: ['30 MAR 2023', '31 MAR 2023', '1 APR 2023', '1 MAY 2023', '2 MAY 2023', '3 MAY 2023']
    const [yAxisForDailyOverdueGraph, setYAxisForDailyOverdueGraph] = useState(undefined) // Sample data format: [135, 100, 100, 100, 80, 50]

    // Styling related values
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const greenColorCode = '#0B6E4F'
    const redColorCode = '#CB152B'
    const secondaryColorCode = '#8C8C9B'
    const allCardHeight = '120px'
    const customStylesForGraphTypeSelectField = {
        option: (styles, data) => {
            return {
                ...styles,
                backgroundColor: data.isSelected || data.isFocused? '#F18D00': 'white'
            }
        },
        container: (styles, data) => {
            return {
                ...styles,
                width: isTabletOrMobile? '100%': '20%'
            }
        },
    }

    // Stacked bar chart styling related
    const layout = {
        barmode: 'stack',
        xaxis: {
            showgrid: false,
            showline: false,
            zeroline: false
        },
        yaxis: {
            tickprefix: 'BDT ', // Add prefix to text
            showgrid: false,
            showline: false,
            zeroline: false
        },
        legend: {
            x: 0, // Position at the left
            y: 1.1, // Position above the chart
            xanchor: 'left', // Anchor the legend's x position to the left
            yanchor: 'bottom', // Anchor the legend's y position to the bottom
            orientation: 'h', // Orient the legend horizontally,
            traceorder: 'normal' // Ensure the legend items are displayed in the order they appear in the data array
        },
        autosize: true, // Ensures the chart adjusts its size dynamically,
        hovermode: 'closest', // Ensures the tooltip shows only the data point closest to the mouse
    }
    // data for the chart
    const paidMonthlyData = {
        x: xAxisForMonthlyPaidGraph,
        y: yAxisForMonthlyPaidGraph,
        name: 'Paid',
        type: 'bar',
        marker: {
            color: greenColorCode
        },
        text: yAxisForMonthlyPaidGraph? yAxisForMonthlyPaidGraph.map(value => 'BDT ' + new Intl.NumberFormat('en-IN').format(value)):'',
        textposition: 'none', // Prevent text from being displayed over bars
        hoverinfo: 'x+y+text', // Determines which trace information appear on hover
        hovertemplate: '<b>Month:</b> %{x}<br><b>Paid:</b> %{text}<br><extra></extra>' // Custom tooltip content
    };
    const overdueMonthlyData = {
        x: xAxisForMonthlyOverdueGraph,
        y: yAxisForMonthlyOverdueGraph,
        name: 'Overdue',
        type: 'bar',
        marker: {
            color: '#BCBCCD'
        },
        text: yAxisForMonthlyOverdueGraph? yAxisForMonthlyOverdueGraph.map(value => 'BDT ' + new Intl.NumberFormat('en-IN').format(value)):'',
        textposition: 'none', // Prevent text from being displayed over bars
        hoverinfo: 'x+y+text', // Determines which trace information appear on hover
        hovertemplate: '<b>Month:</b> %{x}<br><b>Overdue:</b> %{text}<br><extra></extra>', // Custom tooltip content
    };

    const paidDailyData = {
        x: xAxisForDailyPaidGraph,
        y: yAxisForDailyPaidGraph,
        name: 'Paid',
        type: 'bar',
        marker: {
            color: greenColorCode
        },
        text: yAxisForDailyPaidGraph? yAxisForDailyPaidGraph.map(value => 'BDT ' + new Intl.NumberFormat('en-IN').format(value)):'',
        textposition: 'none', // Prevent text from being displayed over bars
        hoverinfo: 'x+y+text', // Determines which trace information appear on hover
        hovertemplate: '<b>Month:</b> %{x}<br><b>Paid:</b> %{text}<br><extra></extra>', // Custom tooltip content
    };
    const overdueDailyData = {
        x: xAxisForDailyOverdueGraph,
        y: yAxisForDailyOverdueGraph,
        name: 'Overdue',
        type: 'bar',
        marker: {
            color: '#BCBCCD'
        },
        text: yAxisForDailyOverdueGraph? yAxisForDailyOverdueGraph.map(value => 'BDT ' + new Intl.NumberFormat('en-IN').format(value)):'',
        textposition: 'none', // Prevent text from being displayed over bars
        hoverinfo: 'x+y+text', // Determines which trace information appear on hover
        hovertemplate: '<b>Month:</b> %{x}<br><b>Overdue:</b> %{text}<br><extra></extra>', // Custom tooltip content
    };

    useEffect(() => {
        if (data) {
            if (data["latest_summary"]) {
                let summaryData = data["latest_summary"]

                summaryData["portfolio_size"]? setPortfolioSize('BDT ' + new Intl.NumberFormat('en-IN').format(parseFloat(summaryData["portfolio_size"].toFixed(2)))):setPortfolioSize("N/A")

                summaryData["portfolio_at_risk"]? setPortfolioAtRisk('BDT ' + new Intl.NumberFormat('en-IN').format(parseFloat(summaryData["portfolio_at_risk"].toFixed(2)))):setPortfolioAtRisk("N/A")
                if (summaryData["portfolio_size_percentage_deviation"]) {
                    setPortfolioAtRiskPercentage(summaryData["portfolio_size_percentage_deviation"].toFixed(2))
                }

                summaryData["total_overdue"]? setTotalOverdue('BDT ' + new Intl.NumberFormat('en-IN').format(parseFloat(summaryData["total_overdue"].toFixed(2)))):setTotalOverdue("N/A")
                if (summaryData["total_overdue_percentage_deviation"]) {
                    setTotalOverduePercentage(summaryData["total_overdue_percentage_deviation"].toFixed(2))
                }

                summaryData["total_paid"]? setTotalCollected('BDT ' + new Intl.NumberFormat('en-IN').format(parseFloat(summaryData["total_paid"].toFixed(2)))):setTotalCollected("N/A")
                if (summaryData["total_paid_percentage_deviation"]) {
                    setTotalCollectionPercentage(summaryData["total_paid_percentage_deviation"].toFixed(2))
                }
            } else {
                setPortfolioSize("N/A")
                setPortfolioAtRisk("N/A")
                setTotalOverdue("N/A")
                setTotalCollected("N/A")
            }

            // ------------------ Begin: Constructing data for "Monthly Projection Graph" --------------------- //
            if (data["yearly_data"]) {
                let yearlyDataFromApi = data["yearly_data"]
                let years = Object.keys(yearlyDataFromApi)
                let xAxisDataForMonthlyPaidGraph = []
                let yAxisDataForMonthlyPaidGraph = []
                let xAxisDataForMonthlyOverdueGraph = []
                let yAxisDataForMonthlyOverdueGraph = []
                for (let i=0; i<years.length; i++) {
                    let yearData = yearlyDataFromApi[years[i]]
                    if (yearData["month_wise_summary"]) {
                        let monthData = yearData["month_wise_summary"]
                        for (let j=0; j<monthData.length; j++) {
                            let month = Object.keys(monthData[j])[0]
                            xAxisDataForMonthlyPaidGraph.push(month)
                            monthData[j][month]["total_paid"]? yAxisDataForMonthlyPaidGraph.push(parseFloat(monthData[j][month]["total_paid"].toFixed(2))):yAxisDataForMonthlyPaidGraph.push(0)

                            xAxisDataForMonthlyOverdueGraph.push(month)
                            monthData[j][month]["total_overdue"]? yAxisDataForMonthlyOverdueGraph.push(parseFloat(monthData[j][month]["total_overdue"].toFixed(2))):yAxisDataForMonthlyOverdueGraph.push(0)
                        }
                    }
                }
                // Monthly paid data
                setXAxisForMonthlyPaidGraph(xAxisDataForMonthlyPaidGraph)
                setYAxisForMonthlyPaidGraph(yAxisDataForMonthlyPaidGraph)
                // Monthly overdue data
                setXAxisForMonthlyOverdueGraph(xAxisDataForMonthlyOverdueGraph)
                setYAxisForMonthlyOverdueGraph(yAxisDataForMonthlyOverdueGraph)
            } else {
                // Monthly paid data
                setXAxisForMonthlyPaidGraph([])
                setYAxisForMonthlyPaidGraph([])
                // Monthly overdue data
                setXAxisForMonthlyOverdueGraph([])
                setYAxisForMonthlyOverdueGraph([])
            }
            // ------------------ End: Constructing data for "Monthly Projection Graph" --------------------- //

            // ------------------ Begin: Constructing data for "Daily Projection Graph" --------------------- //
            if (data["date_wise_summary"]) {
                let dateWiseDataFromApi = data["date_wise_summary"]
                let xAxisDataForDateWiseGraph = []
                let yAxisDataForDateWisePaidGraph = []
                let yAxisDataForDateWiseOverdueGraph = []
                for (let i=0; i<dateWiseDataFromApi.length; i++) {
                    let date = Object.keys(dateWiseDataFromApi[i])[0]
                    dateWiseDataFromApi[i][date]["total_paid"]? yAxisDataForDateWisePaidGraph.push(parseFloat(dateWiseDataFromApi[i][date]["total_paid"].toFixed(2))):yAxisDataForDateWisePaidGraph.push(0)
                    dateWiseDataFromApi[i][date]["total_overdue"]? yAxisDataForDateWiseOverdueGraph.push(parseFloat(dateWiseDataFromApi[i][date]["total_overdue"].toFixed(2))):yAxisDataForDateWiseOverdueGraph.push(0)
                    xAxisDataForDateWiseGraph.push(moment(date, "YYYY-MM-DD").format("DD MMM YYYY"))
                }
                // Daily paid data
                setXAxisForDailyPaidGraph(xAxisDataForDateWiseGraph)
                setYAxisForDailyPaidGraph(yAxisDataForDateWisePaidGraph)
                // Daily overdue data
                setXAxisForDailyOverdueGraph(xAxisDataForDateWiseGraph)
                setYAxisForDailyOverdueGraph(yAxisDataForDateWiseOverdueGraph)
            } else {
                // Daily paid data
                setXAxisForDailyPaidGraph([])
                setYAxisForDailyPaidGraph([])
                // Daily overdue data
                setXAxisForDailyOverdueGraph([])
                setYAxisForDailyOverdueGraph([])
            }
            // ------------------ End: Constructing data for "Daily Projection Graph" --------------------- //
        }
    },[data])

    useEffect(() => {
        if (dataForStaticCards) {
            setTotalBorrower(dataForStaticCards.number_of_borrowers || dataForStaticCards.number_of_borrowers === 0? new Intl.NumberFormat('en-IN').format(dataForStaticCards.number_of_borrowers):'N/A')
            let currentMonthsDeployment = dataForStaticCards.number_of_contract || dataForStaticCards.number_of_contract === 0? new Intl.NumberFormat('en-IN').format(dataForStaticCards.number_of_contract):'N/A'
            let prevMonthsDeployment = dataForStaticCards.previous_month_number_of_contract
            setDeployedThisMonth(currentMonthsDeployment)
            setDeployedPreviousMonth(prevMonthsDeployment)
            let percentageOfDeployment = (currentMonthsDeployment / prevMonthsDeployment) * 100
            setDeploymentPercentage(percentageOfDeployment)

            dataForStaticCards.co2_emission_saved_total || dataForStaticCards.co2_emission_saved_total === 0? setCo2EmissionSaved(new Intl.NumberFormat('en-IN').format(dataForStaticCards.co2_emission_saved_total)):setCo2EmissionSaved('N/A')

            dataForStaticCards.deployed_ev_total || dataForStaticCards.deployed_ev_total === 0? setTotalDeployedBatteries(new Intl.NumberFormat('en-IN').format(dataForStaticCards.deployed_ev_total)):setTotalDeployedBatteries('N/A')
        }
    },[dataForStaticCards])

    const informationCardWithoutFooter = (title, mainContent, percentage='', textColor='') => {
        return <>
            <Card sx={{ width: '100%', height: allCardHeight}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        {title}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1} sx={{ marginTop: '10px'}}>
                        <Typography variant="h4" component="div" sx={textColor? { color: textColor }:{}}>
                            {mainContent}
                        </Typography>
                        {percentage? <>
                            <Typography variant="h6" component="span" sx={{color: secondaryColorCode }}>
                                &#8226;
                            </Typography>
                            <Typography variant="h6" component="span" sx={textColor? {color: textColor}:{}}>
                                {percentage}%
                            </Typography>
                        </>:''}
                    </Box>
                </CardContent>
            </Card>
        </>
    }

    const thisMonthsDeploymentCard = () => {
        return <>
            <Card sx={{ width: '100%', height: allCardHeight}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        Deployed this month
                    </Typography>
                    <Typography variant="h4" component="div" sx={{ marginTop: '10px'}}>
                        {deployedThisMonth}
                    </Typography>
                </CardContent>
                {deployedPreviousMonth && deploymentPercentage? <>
                    <CardActions className={'ml-2'}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
                            {deployedThisMonth > deployedPreviousMonth? <>
                                <ArrowUpwardIcon sx={{ color: greenColorCode }} fontSize={"small"}/>
                            </>:<>
                                <SouthIcon sx={{ color: redColorCode }} fontSize={"small"}/>
                            </>}
                            <Typography variant="h6" component="span" sx={deployedThisMonth > deployedPreviousMonth? { color: greenColorCode }:{ color: redColorCode }}>
                                {deploymentPercentage}%
                            </Typography>
                            <Typography variant="h6" component="span" sx={{color: secondaryColorCode }}>
                                &#8226;
                            </Typography>
                            <Typography variant="h6" component="span" sx={{color: secondaryColorCode }}>
                                vs Previous Month: {new Intl.NumberFormat('en-IN').format(deployedPreviousMonth)}
                            </Typography>
                        </Box>
                    </CardActions>
                </>:''}
            </Card>
        </>
    }

    const projectionTrendCard = () => {
        return <>
            <Card sx={{ width: '100%', minHeight: allCardHeight}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div" className={"d-flex align-items-left"}>
                        <span>Projection Trend</span>
                    </Typography>
                    <div className={'mt-2 d-flex align-items-left'}>
                        <Select
                            placeholder={'Select an option'}
                            classNamePrefix="react-select-sol-style"
                            maxMenuHeight={200}
                            value={projectionTrendGraphType}
                            isDisabled={props.collectingAtAGlanceInfo}
                            isClearable={false}
                            options={projectionTrendGraphTypeOptions}
                            isSearchable={false}
                            noOptionsMessage={() => "No option available"}
                            styles={customStylesForGraphTypeSelectField}
                            onChange={(selected, {action}) => {
                                setProjectionTrendGraphType(selected)
                            }}
                        />
                        <Button size={"sm"} className={"ml-2"} variant={"outline-warning"}
                                onClick={changeProjectionTrendGraph} disabled={props.collectingAtAGlanceInfo}>View</Button>
                    </div>
                    {/* 'Daily' graph data variable related checking is not needed below. Yet, I have added it -
                    Noor Reza, 18h Aug,2024 3:42 PM */}
                    {props.collectingAtAGlanceInfo ||
                    (graphTypeForTheComponent === 'daily' && !(paidDailyData && overdueDailyData)) ||
                    (graphTypeForTheComponent === 'monthly' && !(paidMonthlyData && overdueMonthlyData))? <>
                        <Skeleton variant="rect" sx={{ marginTop: '10px', width: '100%' }} height={440}/>
                    </>:<>
                        <Plot
                            data={graphTypeForTheComponent === 'monthly'? [paidMonthlyData, overdueMonthlyData]:[paidDailyData, overdueDailyData]}
                            useResizeHandler={true} // This property makes the Plotly chart responsive to window resizing.
                            style={{width: '100%', height: '100%'}} // setting height to 100% has some effects that can't be noticed instantly
                            layout={{...layout}}
                            config={isTabletOrMobile? {
                                displayModeBar: false  // Make the mode bar (download, zoom etc. icons) inaccessible in mobile view, in desktop view it becomes visible when user scrolls over the graph
                            }:{}}
                        />
                    </>}
                </CardContent>
            </Card>
        </>
    }

    const changeProjectionTrendGraph = () => {
        setGraphTypeForTheComponent(projectionTrendGraphType.value)
    }

    const informationCardSkeleton = (title) => {
        return <>
            <Card sx={{ width: '100%', height: allCardHeight}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        {title}
                    </Typography>
                    <Skeleton variant="rect" sx={{ marginTop: '10px', width: '100%' }} height={65}/>
                </CardContent>
            </Card>
        </>
    }

    return (
        <>
            {/* Begin: Information cards */}
            <div className={'row'}>
                <div className={isTabletOrMobile? 'col-md-3':'col-md-3 pl-0'}>
                    {props.collectingAtAGlanceInfo || !portfolioSize? <>
                        {informationCardSkeleton('Portfolio Size')}
                    </>:<>
                        {informationCardWithoutFooter('Portfolio Size', portfolioSize)}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-3 mt-2':'col-md-3'}>
                    {props.collectingAtAGlanceInfo || !portfolioAtRisk? <>
                        {informationCardSkeleton('Portfolio At Risk')}
                    </>:<>
                        {informationCardWithoutFooter('Portfolio At Risk', portfolioAtRisk,
                            portfolioAtRiskPercentage, portfolioAtRisk !== 'N/A'? redColorCode:'')}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-3 mt-2':'col-md-3'}>
                    {props.collectingAtAGlanceInfo || !totalOverdue? <>
                        {informationCardSkeleton('Total Overdue')}
                    </>:<>
                        {informationCardWithoutFooter('Total Overdue', totalOverdue,
                            totalOverduePercentage, portfolioAtRisk !== 'N/A'? redColorCode:'')}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-3 mt-2':'col-md-3'}>
                    {props.collectingAtAGlanceInfo || !totalCollected? <>
                        {informationCardSkeleton('Total Collected')}
                    </>:<>
                        {informationCardWithoutFooter('Total Collected', totalCollected,
                            totalCollectionPercentage, portfolioAtRisk !== 'N/A'? greenColorCode:'')}
                    </>}
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={isTabletOrMobile? 'col-md-3':'col-md-3 pl-0'}>
                    {props.collectingSummaryDataFromGarage || !totalBorrower? <>
                        {informationCardSkeleton('Total Borrowers')}
                    </>:<>
                        {informationCardWithoutFooter('Total Borrowers', totalBorrower)}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-3 mt-2':'col-md-3'}>
                    {props.collectingSummaryDataFromGarage || !deployedThisMonth? <>
                        {informationCardSkeleton('Deployed This Month')}
                    </>:<>
                        {thisMonthsDeploymentCard()}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-3 mt-2':'col-md-3'}>
                    {props.collectingSummaryDataFromGarage || !totalDeployedBatteries? <>
                        {informationCardSkeleton('Total deployed batteries')}
                    </>:<>
                        {informationCardWithoutFooter('Total deployed batteries', totalDeployedBatteries)}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-3 mt-2':'col-md-3'}>
                    {props.collectingSummaryDataFromGarage || !co2EmissionSaved? <>
                        {informationCardSkeleton('CO2 Emission Saved (kg)')}
                    </>:<>
                        {informationCardWithoutFooter('CO2 Emission Saved (kg)', co2EmissionSaved)}
                    </>}
                </div>
            </div>
            {/* End: Information cards */}

            {/* Begin: Projection Trend */}
            <div className={'row mt-2'}>
                <div className={isTabletOrMobile? 'col-md-12':'col-md-12 pl-0'}>
                    {projectionTrendCard()}
                </div>
            </div>
            {/* End: Projection Trend */}
        </>
    );
}

AtAGlanceOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingSummaryDataFromGarage: state.overviewReducer.collectingSummaryDataFromGarage,
        collectingAtAGlanceInfo: state.overviewReducer.collectingAtAGlanceInfo
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(AtAGlanceOverview))
