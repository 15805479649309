import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Button, Form, ListGroup, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {makeStyles} from "@material-ui/styles";
import {SolInput, SolTextArea, DisabledSolInput} from "../../../../components/SolStyledComponents/components";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import {DayPickerRangeController} from "react-dates";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {requestCycle} from "../../../borrowers/utils";
import {toast} from "react-toastify";
import {isValidPhoneNumber} from "libphonenumber-js";
import Overlay from 'react-bootstrap/Overlay';
import {showNotifications} from "../../../../utils/notification";
import BranchAddEdit from "../../../../components/branchRelatedComponents/branchAddEditForm";
import InfoIcon from "@mui/icons-material/Info";
import {useMediaQuery} from "react-responsive";
import {styled} from "@mui/material/styles";
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import {addExactOneMonth} from "../../../../utils/monthAdditionSubtraction/exactDateAfterAddingOneMonth";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const ContractCreation = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError, getValues} = useForm();
    const [language, setLanguage] = useState(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const subHeader = useSubheader();

    const [garages, setGarages] = useState([]);
    const [selectedGarage, setSelectedGarage] = useState('');
    const [showSelectedGarageDetails, setShowSelectedGarageDetails] = useState(false)
    const [borrowers, setBorrowers] = useState([]);
    const [showTooltipForResponsiblePersonField, setShowTooltipForResponsiblePersonField] = useState(false);

    const [showTooltipForBranchField, setShowTooltipForBranchField] = useState(false);
    const [showTooltipForDocumentField, setShowTooltipForDocumentField] = useState(false);

    const [showNumberOfInstallmentsField, setShowNumberOfInstallmentsField] = useState(false);
    const [showContractTerminationField, setShowContractTerminationField] = useState(false);
    const [showGracePeriodField, setShowGracePeriodField] = useState(false);

    const [selectedRepaymentMethod, setSelectedRepaymentMethod] = useState('');
    const [showContractRepaymentMethodDetails, setShowContractRepaymentMethodDetails] = useState(false)

    const [gracePeriods, setGracePeriods] = useState([]);
    const [showFirstInstallmentPaymentDate, setShowFirstInstallmentPaymentDate] = useState(false);
    const [firstInstallmentPaymentDate, setFirstInstallmentPaymentDate] = useState('');

    const [smartBatteries, setSmartBatteries] = useState([]);
    const [selectedSmartBattery, setSelectedSmartBattery] = useState('');
    const [showSelectedSmartBatteryDetails, setShowSelectedSmartBatteryDetails] = useState(false)

    const [showPaymentFrequencyField, setShowPaymentFrequencyField] = useState(false)

    const [showLoanStartDatePicker, setShowLoanStartDatePicker] = useState(false);
    const [targetForLoanStartDatePicker, setTargetForLoanStartDatePicker] = useState(null);
    const [date, setDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const [showLoanContractEndDatePicker, setShowLoanContractEndDatePicker] = useState(false);
    const [targetForLoanContractEndDatePicker, setTargetForLoanContractEndDatePicker] = useState(null);
    const [rentContractEndDate, setRentContractEndDate] = useState(null);
    const [dateRange2, setDateRange2] = useState('');
    const [focusedInput2, setFocusedInput2] = useState('startDate');

    const [rentContractDayCount, setRentContractDayCount] = useState(0)

    const [downpaymentProvided, setDownpaymentProvided] = useState(false);

    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [showSelectedBranchDetails, setShowSelectedBranchDetails] = useState(false)
    const [showBranchCreationModal, setShowBranchCreationModal] = useState(false)

    const [disableCreateLoanContractButton, setDisableCreateLoanContractButton] = useState(false)

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: '0.875rem',
            fontWeight: 400
        }
    }));
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    // Tooltip for "Responsible for Payment"
    const toolTipTextForResponsibleForPayment = 'Only persons under the selected garage will be available for selection'
    const toolTipForResponsibleForPaymentField = () => {
        return <>
            <LightTooltip
                title={toolTipTextForResponsibleForPayment} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForResponsiblePersonField}
                onOpen={() => setShowTooltipForResponsiblePersonField(true)}
                onClose={() => setShowTooltipForResponsiblePersonField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForResponsiblePersonField(!showTooltipForResponsiblePersonField)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    // Tooltip for "Branch"
    const toolTipTextForBranchField = 'Only branches under the selected financier will be available for selection'
    const toolTipForBranchField = () => {
        return <>
            <LightTooltip
                title={toolTipTextForBranchField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForBranchField}
                onOpen={() => setShowTooltipForBranchField(true)}
                onClose={() => setShowTooltipForBranchField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForBranchField(!showTooltipForBranchField)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    // Tooltip for "Document"
    const toolTipTextForDocumentField = <>
        <strong>Accepted file types:</strong> *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, '*.jpg, *.webp, *.png, *.csv<br/>
        <strong>Max accepted size:</strong> 10MB
    </>
    const toolTipForDocumentField = () => {
        return <>
            <HtmlTooltip
                title={toolTipTextForDocumentField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForDocumentField}
                onOpen={() => setShowTooltipForDocumentField(true)}
                onClose={() => setShowTooltipForDocumentField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForDocumentField(!showTooltipForDocumentField)
                    }
                }}/>
            </HtmlTooltip>
        </>
    }

    // Contract Repayment method selection related
    const contractRepaymentMethods = [
        {
            label: 'Loan',
            value: 'loan'
        },
        {
            label: 'Lease',
            value: 'lease'
        },
        {
            label: 'Rent',
            value: 'rent'
        }
    ]

    // Payment frequency selection related
    const paymentFrequencies = [
        {
            label: 'Weekly',
            value: 'weekly'
        },
        {
            label: 'Monthly',
            value: 'monthly'
        }
    ]

    // Payment mode selection related
    const paymentModes = [
        {
            label: 'Prepaid',
            value: 'prepaid'
        },
        {
            label: 'Postpaid',
            value: 'postpaid'
        }
    ]

    // Auto payment method selection related
    const autoPaymentStates = [
        {
            label: 'Allowed',
            value: 'allowed'
        },
        {
            label: 'Not Allowed',
            value: 'not_allowed'
        }
    ]
    const customStylesForAutoPaymentStateSelection = {
        option: (styles, data) => {
            return {
                ...styles,
                color: data.value === 'allowed'? '#0b6e4f': '#cb152b'
            }
        },
        singleValue: (styles, selectedData) => {
            return {
                ...styles,
                color: selectedData.data.value === 'allowed'? '#0b6e4f': '#cb152b'
            }
        }
    }

    // "Dowpayment collection channel" selection related
    const downpaymentCollectionChannels = [
        {
            label: 'Cash',
            value: 'cash'
        },
        {
            label: 'Digital',
            value: 'digital'
        }
    ]

    // "Decision to add SaaS Fee for Downpayment collection" selection related
    const saasFeeCollectionDecisions = [
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]

    // "Decision to add SaaS Fee for Downpayment collection" selection related
    const solshareRevenueTypes = [
        {
            label: 'Platform fee',
            value: 'platform_fee'
        },
        {
            label: 'Entire revenue',
            value: 'leasing_fee'
        }
    ]

    const invalidFileType = 'Invalid file type! Accepted file types are *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, *.jpg, *.webp, *.png and *.csv'
    const invalidSize = 'File size shouldn"t exceed 10MB!'

    const pageTitle = 'New Contract'

    const {
        GET_GARAGE_LIST,
        COLLECT_SMART_BATTERY_LIST,
        COLLECT_DRIVER_LIST,
        GET_ORGANISATIONS,
        GET_BRANCHES_LIST,
        CREATE_NEW_BRANCH,
        CREATE_LOAN_CONTRACT
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(
            <>
                <h5 className="text-dark font-weight-bold my-2 mr-5"><a href={'list/'}>Contracts</a>&nbsp;&nbsp;/&nbsp;&nbsp;New Contract</h5>
            </>
        )
        GET_GARAGE_LIST()
        COLLECT_SMART_BATTERY_LIST({'purpose': 'contract'})
        COLLECT_DRIVER_LIST()
        GET_ORGANISATIONS()

        setValue('downpayment_amount', 0)

        // Grace Periods generation
        let optionsForGracePeriod = []
        for (let i= 0; i<=14; i++) {
            optionsForGracePeriod.push({
                label: i === 0 || i === 1? i + ' day':i + ' days',
                value: i
            })
        }
        setValue('grace_period', optionsForGracePeriod[0])
        setGracePeriods(optionsForGracePeriod)
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    useEffect(() => {
        if (props.garages) {
            let garages = props.garages;
            if (garages.length > -1) {
                let filteredGarages = []
                for (let i=0; i<garages.length; i++) {
                    if (garages[i].garage_owner && garages[i].garage_owner_phone && garages[i].garage_owner_guid) {
                        filteredGarages.push({
                            ...garages[i],
                            label: garages[i].name,
                            value: garages[i].pk
                        })
                    }
                }
                setGarages(filteredGarages)
            }
        }
    }, [props.garages])

    useEffect(() => {
        if (props.smartBatteries) {
            const smartBatteries = props.smartBatteries;
            if (smartBatteries.length > -1) {
                let options = []
                for (let i = 0; i < smartBatteries.length; i++) {
                    if (!smartBatteries[i].contract_id) {
                        options.push({
                            ...smartBatteries[i],
                            label: smartBatteries[i].serial_number,
                            value: smartBatteries[i].serial_number
                        })
                    }
                }
                setSmartBatteries(options)
            }
        }
    }, [props.smartBatteries])

    useEffect(() => {
        if (props.organisations) {
            const organisations = props.organisations
            if (organisations.length > -1) {
                setOrganizations(organisations.map((organisation) => {
                    return {
                        ...organisation,
                        label: organisation.name,
                        value: organisation.pk
                    }
                }))
            }
        }
    }, [props.organisations])

    useEffect(() => {
        if (props.branches) {
            const branches = props.branches
            if (branches.length > -1) {
                setBranches(branches.map((branch) => {
                    // Handling the case: Creating a new branch after selecting a branch
                    if (selectedBranch && selectedBranch.value === branch.pk) {
                        setValue('branch_guid', selectedBranch)
                    }

                    let customLabel = ''
                    if (branch.division) {
                        customLabel = branch.name + ', ' + branch.upazila + ', ' + branch.district + ', ' + branch.division
                    } else {
                        customLabel = branch.name + ', ' + branch.upazila + ', ' + branch.district
                    }

                    return {
                        ...branch,
                        label: customLabel,
                        value: branch.pk
                    }
                }))
            }
        }
    }, [props.branches])

    // Schedule - Date field
    const disableDatesForContractStartDateSelection = (current) => {
        const today = moment()
        if (current.isBefore(today.subtract(6, 'months'), 'day') || current.isSameOrAfter(rentContractEndDate, 'day')) {
            return true
        }
        return false
    }

    const onDatesChange = ({startDate, endDate}) => {
        if (startDate && !endDate) {
            setDate(startDate)
            setShowLoanStartDatePicker(false)
        } else if ((startDate && endDate) || (!startDate && endDate)) {
            setDate(endDate)
            setShowLoanStartDatePicker(false)
        }
    }

    useEffect(() => {
        calculateFirstInstallmentPaymentDate()
        if (date) {
            setDateRange(moment(date).format("MMMM DD, YYYY"))
            clearErrors("effective_from")
            if (rentContractEndDate) {
                let durationOfTheContract = moment(rentContractEndDate).diff(date, 'days') + 1
                setRentContractDayCount(durationOfTheContract)
                let installmentAmount = getValues('per_installment_payable')
                if (installmentAmount) {
                    // 85% of expected collectable
                    let projectedRevenue = parseFloat(installmentAmount) * durationOfTheContract * 0.85
                    setValue('projected_revenue', projectedRevenue)
                }
            }
        } else {
            setDateRange('')
            setRentContractDayCount(0)
            setValue('projected_revenue', 0)
        }
    }, [date])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = () => {
        return <>
            <Overlay
                show={showLoanStartDatePicker}
                target={targetForLoanStartDatePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            {...props}
                            startDate={date}
                            endDate={date}
                            numberOfMonths={1}
                            minimumNights={0}
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                            firstDayOfWeek={0}
                            isOutsideRange={disableDatesForContractStartDateSelection}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "effective_from") {
                                    setShowLoanStartDatePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const disableDatesForRentContractEndSelection = (current) => {
        const today = moment()
        if (current.isBefore(today, 'day') || current.isBefore(date, 'day')) {
            return true
        }
        return false
    }

    const onDatesChange2 = ({startDate, endDate}) => {
        if (startDate && !endDate) {
            setRentContractEndDate(startDate)
            setShowLoanContractEndDatePicker(false)
        } else if ((startDate && endDate) || (!startDate && endDate)) {
            setRentContractEndDate(endDate)
            setShowLoanContractEndDatePicker(false)
        }
    }

    useEffect(() => {
        if (rentContractEndDate) {
            setDateRange2(moment(rentContractEndDate).format("MMMM DD, YYYY"))
            clearErrors("effective_till")
            if (date) {
                let durationOfTheContract = moment(rentContractEndDate).diff(date, 'days') + 1
                setRentContractDayCount(durationOfTheContract)
                let installmentAmount = getValues('per_installment_payable')
                if (installmentAmount) {
                    // 85% of expected collectable
                    let projectedRevenue = parseFloat(installmentAmount) * durationOfTheContract * 0.85
                    setValue('projected_revenue', projectedRevenue)
                }
            }
        } else {
            setDateRange2('')
            setRentContractDayCount(0)
            setValue('projected_revenue', 0)
        }
    }, [rentContractEndDate])

    const handleFocusChange2 = (input) => {
        if (!input) {
            setFocusedInput2('startDate')
        } else {
            setFocusedInput2(input)
        }
    }

    const popoverForLoanEndDate = () => {
        return <>
            <Overlay
                show={showLoanContractEndDatePicker}
                target={targetForLoanContractEndDatePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic2" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            {...props}
                            startDate={rentContractEndDate}
                            endDate={rentContractEndDate}
                            numberOfMonths={1}
                            minimumNights={0}
                            onDatesChange={onDatesChange2}
                            focusedInput={focusedInput2}
                            onFocusChange={(focusedInput) => handleFocusChange2(focusedInput)}
                            firstDayOfWeek={0}
                            isOutsideRange={disableDatesForRentContractEndSelection}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "effective_till") {
                                    setShowLoanContractEndDatePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }
    // ---End: Date Field --- //

    const initiateBranchCreationProcess = () => {
        if (selectedOrganization) {
            setShowBranchCreationModal(true)
        } else {
            toast.warning("Please select an organization at first!")
        }
    }

    const hideBranchCreationModal = () => {
        setShowBranchCreationModal(false)
    }

    const informationIsRequired = "Above information is required!"
    const saveBranch = (data) => {
        CREATE_NEW_BRANCH({...data, 'division': data.division.label, 'district': data.district.label,
            'upazila': data.upazila.label, 'zone': data.zone?.label, 'area': data.area?.label,
            'organization_guid': selectedOrganization.value})
    }

    const renderBranchCreationModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideBranchCreationModal}
                modal={showBranchCreationModal}
                title={<h1><strong>Branch Details</strong></h1>}>
                <BranchAddEdit submitFormData={saveBranch} closeForm={hideBranchCreationModal} modalFooter={true}
                               editable={false} branchData={{}} orgGuid={selectedOrganization.pk}/>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.branchCreated && props.branchCreated === requestCycle.success) {
            toast.success("New branch is added successfully!")
            hideBranchCreationModal()
            setTimeout(() => {
                GET_BRANCHES_LIST({'organization_guid': selectedOrganization.value})
            }, 1000);
        }
    }, [props.branchCreated])

    useEffect(() => {
        if (props.errorMessageForBranchCreation) {
            showNotifications('error', props.errorMessageForBranchCreation)
        }
    }, [props.errorMessageForBranchCreation])

    const saveLoanInfo = (data) => {
        let payload = {...data}

        payload['garage_guid'] = data['garage_guid'].value
        payload['borrower_guid'] = data['borrower_guid'].value
        payload['device_serial_number'] = data['device_serial_number'].value
        payload['financier_guid'] = data['financier_guid'].value
        payload['branch_guid'] = data['branch_guid'].value
        payload['contract_type'] = data['contract_type'].value
        payload['prepaid_payment_enabled'] = data['payment_mode'] === 'prepaid'
        delete payload['payment_mode']
        payload['effective_from'] = moment(data.effective_from).format("YYYY-MM-DD")
        if (data['effective_till']) {
            payload['effective_till'] = moment(data.effective_till).format("YYYY-MM-DD")
        }
        payload['enable_auto_payment'] = data['enable_auto_payment'].value === 'allowed'
        payload['grace_period'] = data['grace_period']? parseInt(data['grace_period'].value):0
        payload['solshare_revenue'] = data['solshare_revenue'].value
        if (data['contract_type'].value === 'rent') {
            payload['per_rent_payable'] = parseFloat(data['per_installment_payable'])
            delete payload['per_installment_payable']
            payload['loan_amount'] = parseFloat(data['total_investment_amount'])
        } else {
            payload['lease_payment_interval'] = data['lease_payment_interval'].value
            payload['per_installment_payable'] = parseFloat(data['per_installment_payable'])
            payload['loan_duration'] = parseInt(data['number_of_installments'])
        }
        payload['downpayment_channel'] = data['downpayment_channel'].value
        payload['saas_on_downpayment'] = data['saas_on_downpayment'].value === 'yes'
        payload['total_investment_amount'] = parseFloat(data['total_investment_amount'])
        payload['projected_revenue'] = parseFloat(getValues('projected_revenue'))
        payload['platform_fee_percentage'] = parseInt(data['platform_fee_percentage'])
        if (data['contract_file'][0]) {
            payload['contract_file'] = data['contract_file'][0]
        } else {
            delete payload['contract_file']
        }
        CREATE_LOAN_CONTRACT(payload)
    }

    useEffect(() => {
        if (props.loanCreated && props.loanCreated === requestCycle.success) {
            toast.success("New contract has been created successfully!")
            props.history.push({pathname: "/contract-management/list"})
        }
    }, [props.loanCreated])

    useEffect(() => {
        if (props.errorMessageForLoanCreation) {
            showNotifications('error', props.errorMessageForLoanCreation)
        }
    }, [props.errorMessageForLoanCreation])

    const resetProvidedData = () => {
        setDisableCreateLoanContractButton(false)
        // Reset all fields
        // For controlled components (Select fields), a default value is needed to be passed to reset
        // Other fields will be reset automatically
        reset({
            "garage_guid": "",
            "borrower_guid": "",
            "device_serial_number": "",
            "financier_guid": "",
            "branch_guid": "",
            "contract_type": "",
            "number_of_installments": "",
            "lease_payment_interval": "",
            "payment_mode": "",
            "effective_from": "",
            "effective_till": "",
            "enable_auto_payment": "",
            "grace_period": gracePeriods[0],
            "solshare_revenue": "",
            "per_installment_payable": "",
            "downpayment_amount": 0, // Always keeping 0 value at the down payment (input) field
            "downpayment_channel": "",
            "saas_on_downpayment": "",
            "total_investment_amount": "",
            "platform_fee_percentage": "3",
            "projected_revenue": 0, // Always keeping 0 value at the projected revenue (input) field
            "nominee_name": "",
            "nominee_phone": "",
            "associated_organization": "",
            "reference_id": "",
            "contract_file": null, // Reset the uploaded file (if any) (Ref: https://github.com/orgs/react-hook-form/discussions/7650)
            "nominee_details": ""
        })

        // Setting default values in other variables
        setShowSelectedGarageDetails(false)
        setShowNumberOfInstallmentsField(false)
        setShowContractTerminationField(false)
        setShowGracePeriodField(false)
        setShowContractRepaymentMethodDetails(false)
        setShowSelectedSmartBatteryDetails(false)
        setShowPaymentFrequencyField(false)
        setShowSelectedBranchDetails(false)

        // Clearing values from date related variables
        setDate(null) // This will also automatically clear the first payment date text
        setRentContractEndDate(null)
    }

    const calculateFirstInstallmentPaymentDate = () => {
        let paymentFrequency = getValues('lease_payment_interval')
        let gracePeriod = getValues('grace_period')
        let contractType = getValues('contract_type')
        if (date && gracePeriod && paymentFrequency && contractType && ['loan', 'lease'].includes(contractType.value)) {
            if (paymentFrequency.value === 'weekly') {
                let daysNeedToBeAdded = gracePeriod.value + 6
                setFirstInstallmentPaymentDate(moment(date).add(daysNeedToBeAdded, 'days'))
            } else {
                let nextCycleStartDate = addExactOneMonth(date).add(gracePeriod.value, 'days')
                setFirstInstallmentPaymentDate(nextCycleStartDate.subtract(1, 'days'))
            }
            setShowFirstInstallmentPaymentDate(true)
        } else {
            setShowFirstInstallmentPaymentDate(false)
            setFirstInstallmentPaymentDate('')
        }
    }


    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Form onSubmit={handleSubmit(saveLoanInfo)}>
                            {/*-- Start: Basic Information ---*/}
                            <div className={'row g-3'}>
                                <div className={'col-md-12'}>
                                    <h2><strong>Basic Information</strong></h2>
                                </div>
                            </div>
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Select Garage&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"garage_guid"}
                                            rules={{required:informationIsRequired}}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a garage'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingGarages}
                                                    isLoading={props.collectingGarages}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={garages}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setShowSelectedGarageDetails(false)
                                                            setSelectedGarage('')
                                                            setBorrowers([])
                                                            setValue('borrower_guid', '')
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setShowSelectedGarageDetails(true)
                                                            setSelectedGarage(selected)
                                                            setBorrowers([{
                                                                ...selected,
                                                                label: selected.garage_owner,
                                                                value: selected.garage_owner_guid
                                                            }])
                                                            setValue('borrower_guid', '')
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {showSelectedGarageDetails && selectedGarage? <>
                                            <div className="small">
                                                {selectedGarage.address? <>
                                                    {selectedGarage.thana? <>
                                                        {selectedGarage.address}, {selectedGarage.thana}, {selectedGarage.upazila}, {selectedGarage.district}
                                                    </>:<>
                                                        {selectedGarage.address}, {selectedGarage.upazila}, {selectedGarage.district}
                                                    </>}
                                                </>:<>
                                                    {selectedGarage.thana? <>
                                                        {selectedGarage.thana}, {selectedGarage.upazila}, {selectedGarage.district}
                                                    </>:<>
                                                        {selectedGarage.upazila}, {selectedGarage.district}
                                                    </>}
                                                </>}
                                            </div>
                                        </>:null}
                                        {errors.garage_guid && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage_guid.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Responsible for Payment&nbsp;{toolTipForResponsibleForPaymentField()}&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"borrower_guid"}
                                            rules={{required:informationIsRequired}}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a responsible person'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingGarages}
                                                    isLoading={props.collectingGarages}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={borrowers}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => "No person available"}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.borrower_guid && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.borrower_guid.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Select Smart Battery&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"device_serial_number"}
                                            rules={{required:informationIsRequired}}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a Smart Battery'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.smartBatteryListDataCollectionInProgress}
                                                    isLoading={props.smartBatteryListDataCollectionInProgress}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={smartBatteries}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setShowSelectedSmartBatteryDetails(false)
                                                            setSelectedSmartBattery('')
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setShowSelectedSmartBatteryDetails(true)
                                                            setSelectedSmartBattery(selected)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {showSelectedSmartBatteryDetails && selectedSmartBattery? <>
                                            <div className="small">
                                                {selectedSmartBattery.battery_details?.battery_vendor},&nbsp;{selectedSmartBattery.battery_details?.nameplate_capacity}Ah,&nbsp;{selectedSmartBattery.battery_details?.nameplate_voltage}V
                                            </div>
                                        </>:null}
                                        {errors.device_serial_number && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.device_serial_number.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Battery Financed by&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"financier_guid"}
                                            rules={{ required: informationIsRequired }}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select an organization'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.orgListLoading}
                                                    isLoading={props.orgListLoading}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={organizations}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setSelectedOrganization('')
                                                            setSelectedBranch('')
                                                            setValue('branch_guid', '')
                                                            setBranches([])
                                                            setShowSelectedBranchDetails(false)
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setSelectedOrganization(selected)
                                                            setSelectedBranch('')
                                                            setValue('branch_guid', '')
                                                            setBranches([])
                                                            setShowSelectedBranchDetails(false)
                                                            GET_BRANCHES_LIST({'organization_guid': selected.value})
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.financier_guid && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.financier_guid.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Financier Organization Branch&nbsp;{toolTipForBranchField()}&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"branch_guid"}
                                            rules={{ required: informationIsRequired }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a branch'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingBranches}
                                                    isLoading={props.collectingBranches}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={branches}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => "No branch available"}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setSelectedBranch('')
                                                            setShowSelectedBranchDetails(false)
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setSelectedBranch(selected)
                                                            setShowSelectedBranchDetails(true)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {showSelectedBranchDetails && selectedBranch? <>
                                            <div className="small">
                                                Area Manager: {selectedBranch.contact_person_name}, {selectedBranch.contact_person_phone}
                                            </div>
                                        </>:''}
                                        {errors.branch_guid && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.branch_guid.message}</div>}
                                        <div>Can't find the branch name?&nbsp;<a style={{color: "#F18D00"}} onClick={() => {!props.collectingBranches? initiateBranchCreationProcess():toast.warning("Branch list is still loading!")}}>Add New Branch Office Details</a>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            {/*-- End: Basic Information ---*/}

                            {/*-- Start: Contract Specifications ---*/}
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-12'}>
                                    <h2><strong>Contract Specifications</strong></h2>
                                </div>
                            </div>
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Contract Repayment Method&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"contract_type"}
                                            rules={{required:informationIsRequired}}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a repayment method'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={contractRepaymentMethods}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setShowContractRepaymentMethodDetails(false)
                                                            setSelectedRepaymentMethod('')

                                                            setShowNumberOfInstallmentsField(false)
                                                            setValue("number_of_installments", '')

                                                            setShowPaymentFrequencyField(false)

                                                            setShowContractTerminationField(false)
                                                            setRentContractEndDate(null)

                                                            setShowGracePeriodField(false)

                                                            setValue("projected_revenue", 0)
                                                        }
                                                        onChange(selected)
                                                        calculateFirstInstallmentPaymentDate()
                                                        if (selected) {
                                                            setShowContractRepaymentMethodDetails(true)
                                                            setSelectedRepaymentMethod(selected)
                                                            if (selected.value === 'loan' || selected.value === 'lease') {
                                                                setShowNumberOfInstallmentsField(true)
                                                                setShowPaymentFrequencyField(true)
                                                                setShowContractTerminationField(false)
                                                                setShowGracePeriodField(true)
                                                            } else {
                                                                setShowNumberOfInstallmentsField(false)
                                                                setShowPaymentFrequencyField(false)
                                                                setShowContractTerminationField(true)
                                                                setShowGracePeriodField(false)
                                                            }
                                                            setValue("number_of_installments", '')
                                                            setValue("projected_revenue", 0)
                                                            setRentContractEndDate(null)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {showContractRepaymentMethodDetails && selectedRepaymentMethod? <>
                                            <div className="small">
                                                {selectedRepaymentMethod.value === 'lease'? <>
                                                    A fixed amount will be collected daily, weekly or monthly
                                                </>:<>
                                                    {selectedRepaymentMethod.value === 'rent'? <>
                                                        A fixed amount will be collected during rent creation
                                                    </>:<>
                                                        {/* Loan */}
                                                        A fixed amount will be collected daily, weekly, monthly, until full amortization
                                                    </>}
                                                </>}
                                            </div>
                                        </>:null}
                                        {errors.contract_type && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contract_type.message}</div>}
                                    </Form.Group>
                                </div>
                                {showNumberOfInstallmentsField? <>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Number of installments&nbsp;<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <SolInput
                                                name={'number_of_installments'}
                                                type={"number"}
                                                min={"1"}
                                                step={"1"}
                                                placeholder={"Provide number of installments"}
                                                autoComplete={"off"}
                                                ref={register({
                                                    required: informationIsRequired
                                                })}
                                                onWheel={(event) => event.target.blur()}
                                                onChange={(event) => {
                                                    let numberOfInstallments = parseFloat(event.target.value)
                                                    if (numberOfInstallments) {
                                                        let installmentAmount = getValues('per_installment_payable')
                                                        if (installmentAmount) {
                                                            let projectedRevenue = numberOfInstallments * parseFloat(installmentAmount)
                                                            setValue('projected_revenue', projectedRevenue.toFixed(2))
                                                        }
                                                    } else {
                                                        setValue('projected_revenue', 0)
                                                    }
                                                }}
                                            />
                                            {errors.number_of_installments && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.number_of_installments.message}</div>}
                                        </Form.Group>
                                    </div>
                                </>:''}
                            </div>
                            <div className={'row g-3'}>
                                {showPaymentFrequencyField? <>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Payment Frequency&nbsp;<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"lease_payment_interval"}
                                                rules={{required:informationIsRequired}}
                                                defaultValue={''}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={'Select payment frequency'}
                                                        classNamePrefix="react-select-sol-style"
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={paymentFrequencies}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                            calculateFirstInstallmentPaymentDate()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.lease_payment_interval && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.lease_payment_interval.message}</div>}
                                        </Form.Group>
                                    </div>
                                </>:''}
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Payment Mode&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"payment_mode"}
                                            rules={{required:informationIsRequired}}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a payment mode'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={paymentModes}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.payment_mode && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.payment_mode.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Contract Start Date&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'effective_from'}
                                            readOnly={true}
                                            value={dateRange}
                                            ref={register({
                                                required: informationIsRequired
                                            })}
                                            placeholder={"Select a date"}
                                            onClick={(e) => {
                                                setTargetForLoanStartDatePicker(e.target)
                                                setShowLoanStartDatePicker(!showLoanStartDatePicker)
                                            }}
                                        />
                                        {errors.effective_from && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.effective_from.message}</div>}
                                    </Form.Group>
                                </div>
                                {showContractTerminationField? <>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Contract Termination Date&nbsp;<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <SolInput
                                                name={'effective_till'}
                                                value={dateRange2}
                                                placeholder={"Select a date"}
                                                autoComplete={"off"}
                                                ref={register({
                                                    required: informationIsRequired
                                                })}
                                                onClick={(e) => {
                                                    setTargetForLoanContractEndDatePicker(e.target)
                                                    setShowLoanContractEndDatePicker(!showLoanContractEndDatePicker)
                                                }}
                                            />
                                            {errors.effective_till && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.effective_till.message}</div>}
                                        </Form.Group>
                                    </div>
                                </>:''}
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Auto Payment <span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"enable_auto_payment"}
                                            defaultValue={autoPaymentStates[0]}
                                            rules={{required: informationIsRequired}}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Enable or disable auto payment'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={autoPaymentStates}
                                                    isSearchable={true}
                                                    styles={customStylesForAutoPaymentStateSelection}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.enable_auto_payment && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.enable_auto_payment.message}</div>}
                                    </Form.Group>
                                </div>
                                {showGracePeriodField? <>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Grace Period</Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"grace_period"}
                                                defaultValue={gracePeriods.length > 1? gracePeriods[0]:''}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={'Select grace period'}
                                                        classNamePrefix="react-select-sol-style"
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={gracePeriods}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                            calculateFirstInstallmentPaymentDate()
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.grace_period && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.grace_period.message}</div>}
                                            {showFirstInstallmentPaymentDate && firstInstallmentPaymentDate? <>
                                                <div className={'small'}>
                                                    1st installment payment date: <strong>{firstInstallmentPaymentDate.format("MMMM DD, YYYY")}</strong>
                                                </div>
                                            </>:''}
                                        </Form.Group>
                                    </div>
                                </>:''}
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>SOLshare's Revenue <span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"solshare_revenue"}
                                            defaultValue={''}
                                            rules={{required: informationIsRequired}}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select type'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={solshareRevenueTypes}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.solshare_revenue && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.solshare_revenue.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {/*-- End: Contract Specifications ---*/}

                            {/*-- Start: Payment Specifications ---*/}
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-12'}>
                                    <h2><strong>Payment Specifications</strong></h2>
                                </div>
                            </div>
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            {/* If contract termination date is shown, that means it is rent contract */}
                                            <Form.Label>{showContractTerminationField? 'Rent Amount (Tk)':'Installment Amount (Tk)'}&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'per_installment_payable'}
                                            type={"number"}
                                            min={"0"}
                                            max={"300000"}
                                            step={"0.01"}
                                            placeholder={"Provide per installment payable in TK"}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired
                                            })}
                                            onWheel={(event) => event.target.blur()}
                                            onChange={(event) => {
                                                let installmentAmount = event.target.value
                                                let projectedRevenue = 0
                                                if (installmentAmount) {
                                                    let contractPaymentMethod = getValues('contract_type')
                                                    let numberOfInstallments = getValues('number_of_installments')
                                                    if (contractPaymentMethod && numberOfInstallments && (contractPaymentMethod.value === 'loan' || contractPaymentMethod.value === 'lease')) {
                                                        projectedRevenue = parseFloat(numberOfInstallments) * parseFloat(installmentAmount)
                                                    } else if (contractPaymentMethod && contractPaymentMethod.value === 'rent' && rentContractDayCount) {
                                                        projectedRevenue = rentContractDayCount * parseFloat(installmentAmount) * 0.85
                                                    }
                                                }
                                                setValue('projected_revenue', projectedRevenue.toFixed(2))
                                            }}
                                        />
                                        {errors.per_installment_payable && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.per_installment_payable.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Downpayment (Tk)</Form.Label>
                                        </div>
                                        <SolInput
                                            name={'downpayment_amount'}
                                            type={"number"}
                                            min={"0"}
                                            max={"300000"}
                                            step={"1"}
                                            placeholder={"Provide downpayment in TK (if any)"}
                                            autoComplete={"off"}
                                            ref={register()}
                                            onWheel={(event) => event.target.blur()}
                                            onChange={(event) => {
                                                let value = event.target.value
                                                if (value && parseInt(value) !== 0) {
                                                    setDownpaymentProvided(true)
                                                } else {
                                                    setDownpaymentProvided(false)
                                                }
                                            }}
                                        />
                                        {errors.downpayment_amount && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.downpayment_amount.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Downpayment Collection&nbsp;{downpaymentProvided? <><span className="required text-danger">*</span></>:''}</Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"downpayment_channel"}
                                            defaultValue={''}
                                            rules={{required: downpaymentProvided? informationIsRequired:false}}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select a channel'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={downpaymentCollectionChannels}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.downpayment_channel && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.downpayment_channel.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>SaaS fee for Downpayment&nbsp;{downpaymentProvided? <><span className="required text-danger">*</span></>:''}</Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"saas_on_downpayment"}
                                            defaultValue={''}
                                            rules={{required: downpaymentProvided? informationIsRequired:false}}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Enable or disable SaaS fee for downpayment'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={saasFeeCollectionDecisions}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.saas_on_downpayment && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.saas_on_downpayment.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Total Investment Amount (Tk)&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'total_investment_amount'}
                                            type={"number"}
                                            min={"0"}
                                            max={"300000000"}
                                            step={"0.01"}
                                            placeholder={"Provide total investment amount in TK"}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired
                                            })}
                                            onWheel={(event) => event.target.blur()}
                                        />
                                        {errors.total_investment_amount && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.total_investment_amount.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Projected Revenue (Tk)</Form.Label>
                                        </div>
                                        <DisabledSolInput
                                            name={'projected_revenue'}
                                            type={"number"}
                                            min={"0"}
                                            max={"300000000"}
                                            step={"0.01"}
                                            placeholder={"Auto calculated projected revenue in TK"}
                                            autoComplete={"off"}
                                            disabled={true}
                                            defaultValue={0}
                                            ref={register()}
                                        />
                                        {errors.projected_revenue && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.projected_revenue.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>SOLshare Platform Fee (SaaS) (%)&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'platform_fee_percentage'}
                                            type={"number"}
                                            min={"0"}
                                            max={"10"}
                                            step={"0.01"}
                                            defaultValue={"3"}
                                            placeholder={"SOLshare Platform Fee in TK"}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired
                                            })}
                                            onWheel={(event) => event.target.blur()}
                                        />
                                        {errors.platform_fee_percentage && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.platform_fee_percentage.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {/*-- End: Payment Specifications ---*/}

                            {/*-- Additional Info --*/}
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-12'}>
                                    <h3><strong>Additional Info</strong></h3>
                                </div>
                            </div>
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Nominee / Referred By</Form.Label>
                                        </div>
                                        <SolInput
                                            name={"nominee_name"}
                                            type={"text"}
                                            placeholder={"Provide nominee name"}
                                            autoComplete={"off"}
                                            ref={register({
                                                validate: {
                                                    // For bengali name validation, we have used the same validation process used at the "Borrower name" validation
                                                    // Also we take some inspiration from here - https://stackoverflow.com/questions/6504983/regular-expression-for-organisation-name
                                                    // - Noor Reza, 25th February, 1:33 PM
                                                    isValidName: value => (/^[a-zA-Z0-9]([a-zA-Z0-9]|[-'. ])+$/.test(value) || !value) || "Invalid Name!"
                                                }
                                            })}
                                        />
                                        {errors.nominee_name && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nominee_name.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Contact Number</Form.Label>
                                        </div>
                                        <SolInput
                                            name={"nominee_phone"}
                                            type={"text"}
                                            placeholder={"Provide nominee contact number"}
                                            autoComplete={"off"}
                                            ref={register({
                                                validate: {
                                                    isValidPhone: (value) => (isValidPhoneNumber(value, 'BD') || !value) || "Invalid contact number!"
                                                }
                                            })}
                                        />
                                        {errors.nominee_phone && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nominee_phone.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Associated Organization or Garage</Form.Label>
                                        </div>
                                        <SolInput
                                            name={"associated_organization"}
                                            type={"text"}
                                            placeholder={"Provide the name of the associated organization or garage"}
                                            autoComplete={"off"}
                                            ref={register({
                                                validate: {
                                                    // For bengali name validation, we have used the same validation process used at the "Borrower name" validation
                                                    // We are allowing all bengali characters: Unicode source - https://github.com/lifeparticle/Bengali-Alphabet
                                                    // Also we take some inspiration from here - https://stackoverflow.com/questions/6504983/regular-expression-for-organisation-name
                                                    // - Noor Reza, 25th February, 1:33 PM
                                                    isValidName: value => (/^[a-zA-Z0-9\u0980-\u09FF]([a-zA-Z0-9\u0980-\u09FF]|[-'.& ])+$/.test(value) || !value) || "Invalid Name!"
                                                }
                                            })}
                                        />
                                        {errors.associated_organization && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.associated_organization.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Reference ID</Form.Label>
                                        </div>
                                        <SolInput
                                            name={"reference_id"}
                                            type={"text"}
                                            placeholder={"Provide reference ID (if any)"}
                                            autoComplete={"off"}
                                            ref={register({
                                                validate: {
                                                    // Allowing only alpha numeric characters and hyphen '-'
                                                    // - Noor Reza, 25th February, 1:33 PM
                                                    isValidReferenceId: value => (/^[a-zA-Z0-9]([a-zA-Z0-9]|[-])+$/.test(value) || !value) || "Only alphanumeric characters and hyphen used between them are considered valid!"
                                                }
                                            })}
                                        />
                                        {errors.reference_id && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reference_id.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Documents {toolTipForDocumentField()}</Form.Label>
                                        </div>
                                        <SolInput
                                            type={"file"}
                                            name={"contract_file"}
                                            autoComplete={"off"}
                                            ref={register()}
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    let value = e.target.files[0]
                                                    if (value) {
                                                        if (!["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                            "application/pdf", "application/vnd.ms-excel",
                                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                            "image/jpeg", "image/webp", "image/png", "text/csv"].includes(value.type)) {
                                                            setError('contract_file', {'message': invalidFileType})
                                                            setDisableCreateLoanContractButton(true)
                                                        } else if (value.size/(1024 * 1024) > 10) {
                                                            setError('contract_file', {'message': invalidSize})
                                                            setDisableCreateLoanContractButton(true)
                                                        } else {
                                                            clearErrors('contract_file')
                                                            setDisableCreateLoanContractButton(false)
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.contract_file && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contract_file.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Address</Form.Label>
                                        </div>
                                        <SolTextArea
                                            name={"nominee_details"}
                                            rows={"3"}
                                            placeholder={"Provide address of the nominee"}
                                            autoComplete={"off"}
                                            ref={register({
                                                validate: {
                                                    // Copied directly from the borrower registration process
                                                    isValidAddress: value => (/^[a-zA-Z0-9\s,/'() -]+$/.test(value) || !value) || "Invalid address!"
                                                }
                                            })}
                                        />
                                        {errors.nominee_details && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nominee_details.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className={'row mt-3'}>
                                <div className={'col-md-12'}>
                                    <hr style={{
                                        border: 'none',
                                        height: '1px',
                                        backgroundColor: '#bbbbbb',
                                        color: '#bbbbbb'
                                    }}/>
                                </div>
                            </div>

                            <div className={'row mt-3'}>
                                <div className={"col-md-12"}>
                                    <Button variant="warning" size={'sm'} type="submit"
                                            disabled={disableCreateLoanContractButton || props.loanCreationInProgress}>
                                        {props.loanCreationInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/>&nbsp;</>:null}Create Contract
                                    </Button>
                                    <Button variant="outline-dark" size={'sm'} disabled={props.loanCreationInProgress}
                                            onClick={()=> {resetProvidedData()}} className={'ml-3'}>
                                        Reset
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>

                {popover()}
                {popoverForLoanEndDate()}
                {renderBranchCreationModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              .custom-popover {
                min-width: fit-content;
                min-height: fit-content;
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

ContractCreation.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingGarages: state.commonReducer.collectingGarages,
        garages: state.commonReducer.garages,
        smartBatteryListDataCollectionInProgress: state.contractManagementReducer.smartBatteryListDataCollectionInProgress,
        smartBatteries: state.contractManagementReducer.smartBatteries,
        driverListLoading: state.contractManagementReducer.driverListLoading,
        driverList: state.contractManagementReducer.driverList,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        collectingBranches: state.commonReducer.collectingBranches,
        branches: state.commonReducer.branches,
        branchCreated: state.commonReducer.branchCreated,
        errorMessageForBranchCreation: state.commonReducer.errorMessageForBranchCreation,
        loanCreated: state.contractManagementReducer.loanCreated,
        loanCreationInProgress: state.contractManagementReducer.loanCreationInProgress,
        errorMessageForLoanCreation: state.contractManagementReducer.errorMessageForLoanCreation
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(ContractCreation));
