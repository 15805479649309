import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {requestCycle} from "../../utils";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {isValidPhoneNumber} from "libphonenumber-js";
import isEmail from 'validator/lib/isEmail';
import {useSubheader} from "../../../../../_metronic/layout";
import InfoIcon from '@mui/icons-material/Info';
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {useMediaQuery} from "react-responsive";


const CreateUser = props => {
    const {register, handleSubmit, errors, setError, clearErrors, control, getValues, setValue} = useForm();
    const location = useLocation();
    const [language, setLanguage] = useState("EN");
    const [isSOlshareUser, setIsSOlshareUser] = useState(false);
    const [userName, setUserName] = useState(sessionStorage.getItem('username') ? sessionStorage.getItem('username') : "");

    const [organisations, setOrganisations] = useState([]);
    const [loggedInUsersOrg, setLoggedInUsersOrg] = useState("");
    const [roles, setRoles] = useState([]);
    const [garages, setGarages] = useState([]);
    const [edit, setEdit] = useState(!!sessionStorage.getItem('edit'));
    const [errorMessage, setErrorMessage] = useState(false);
    const user_types = [
        {
            value: "garage_owner",
            label: "Garage Owner"
        },
        {
            value: "garage_operator",
            label: "Garage Operator"
        },
    ]

    const subHeader = useSubheader();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const garageSelectionHelpText = 'Only garages under the selected organization will be available for selection'
    const [showGarageSelectionHelperTooltip, setShowGarageSelectionHelperTooltip] = useState(false);
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: '0.875rem',
            fontWeight: 400
        }
    }));

    const {
        GET_ROLES,
        GET_ORGANISATIONS,
        GET_GARAGES,
        RETURN_TO_INITIAL_USER_REDUCER_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        setIsSOlshareUser(props.is_solshare_user)
        subHeader.setActionButtons(null)
        if (props.is_solshare_user) {
            GET_ORGANISATIONS()
        } else {
            setLoggedInUsersOrg(props.organization_guid)
        }
        GET_ROLES()
        if (!props.is_solshare_user) {
            // Organization (guid) based filtering is not needed, as all returned API response normally gets filtered based on the
            // logged in user's organization - Noor Reza, 26th October, 5:21 PM
            GET_GARAGES()
        }
        if (location.purpose && location.purpose === 'create') {
            setEdit(false)
            clearSessionStorages()
        }
        if (location.userData) {
            sessionStorage.setItem('edit', true)
            setEdit(true)

            let name = location.userData.name
            sessionStorage.setItem('name', name)
            setValue('name', name)

            let email = location.userData.email
            if (email) {
                sessionStorage.setItem('email', email)
                setValue('email', email)
            }

            sessionStorage.setItem('roleGuid', location.userData.role_guid)
            sessionStorage.setItem('org', location.userData.organization_guid)
            sessionStorage.setItem('userType', location.userData.user_type)
            if (location.userData.garage_guids) {
                sessionStorage.setItem('garageGuids', location.userData.garage_guids[0])
            }
            sessionStorage.setItem('username', location.userData.username)
            sessionStorage.setItem('accessibleOrgs', location.userData.organization_guids)
            setUserName(location.userData.username)
            if (location.userData?.user_type) {
                setUserTypeDropDown(location.userData?.user_type)
            }
            GET_GARAGES({'organization_guid': location.userData.organization_guid})
        }
        if (edit) {
            // Dealing with reloading the update page
            // Name
            setValue('name', sessionStorage.getItem('name'))
            // Email
            if (sessionStorage.getItem('email')) {
                setValue('email', sessionStorage.getItem('email'))
            }

            GET_GARAGES({'organization_guid': sessionStorage.getItem('org')})

            if (sessionStorage.getItem('userType')) {
                setUserTypeDropDown(sessionStorage.getItem('userType'))
            }
        }
        return () => {
            RETURN_TO_INITIAL_USER_REDUCER_STATE()
        }
    }, [])

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('email')
        sessionStorage.removeItem('roleGuid')
        sessionStorage.removeItem('org')
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('garageGuids')
        sessionStorage.removeItem('accessibleOrgs')
    }

    useEffect(() => {
        if (props.successMessage !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessage)
            } else {
                toast.success('সফলভাবে ব্যবহারকারী নিবন্ধন সম্পন্ন হয়েছে!')
            }
        } else if (props.errorMessage !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessage, true))
        }
    }, [props.successMessage, props.errorMessage]);

    useEffect(() => {
        if (props.successMessageEdit !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageEdit)
            } else {
                toast.success('সফলভাবে ব্যবহারকারীর তথ্য সম্পাদিত হয়েছে!')
            }
        } else if (props.errorMessageEdit !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessageEdit, true))
        }
    }, [props.successMessageEdit, props.errorMessageEdit]);

    useEffect(() => {
        if (props.errorMessageRelatedToRoleListCollection) {
            showNotifications('error', props.errorMessageRelatedToRoleListCollection)
        }
    }, [props.errorMessageRelatedToRoleListCollection])

    useEffect(() => {
        if (props.errorMessageForOrgCollection) {
            showNotifications('error', props.errorMessageForOrgCollection)
        }
    }, [props.errorMessageForOrgCollection])

    useEffect(() => {
        if (props.errorMessageForGarageCollection) {
            showNotifications('error', props.errorMessageForGarageCollection)
        }
    }, [props.errorMessageForGarageCollection])

    const setUserTypeDropDown = (user_type) => {
        const temp = user_types.find((item) => item.value === user_type)
        if (temp && Object.keys(temp)?.length === 2) {
            setValue("user_type", temp)
        }
    }

    useEffect(() => {
        if (props.userUpdated === requestCycle.success) {
            clearSessionStorages()
            props.history.goBack()
        }
    }, [props.userUpdated])

    useEffect(() => {
        if (props.userCreated === requestCycle.success) {
            props.history.push('/users/list/');
        }
    }, [props.userCreated])

    useEffect(() => {
        const organisations = props.organisations;

        if (organisations && organisations.length >= 0) {
            let accessibleOrganizations = []
            setOrganisations(organisations.map((organisation) => {
                if (edit) {
                    // Update
                    if (sessionStorage.getItem('org') && organisation.pk === sessionStorage.getItem('org')) {
                        setValue('organisation', {
                            label: organisation.name,
                            value: organisation.pk
                        })
                    }
                    if (isSOlshareUser && sessionStorage.getItem('accessibleOrgs') && sessionStorage.getItem('accessibleOrgs').includes(organisation.pk)) {
                        accessibleOrganizations.push({
                            label: organisation.name,
                            value: organisation.pk
                        })
                    }
                } else if (organisation.pk === props.organization_guid && !isSOlshareUser) {
                    // Create
                    setValue('organisation', {
                        label: organisation.name,
                        value: organisation.pk
                    })
                }
                return {
                    ...organisation,
                    label: organisation.name,
                    value: organisation.pk
                }
            }));
            setValue('organizationsWhoWillHaveAccess', accessibleOrganizations)
        }
    }, [props.organisations, isSOlshareUser])

    useEffect(() => {
        const roleList = props.roles;

        if (roleList && roleList.length >= 0) {
            setRoles(roleList.map((role) => {
                if (edit && sessionStorage.getItem('roleGuid') && role.pk === sessionStorage.getItem('roleGuid')) {
                    setValue('role', {
                        label: role.name,
                        value: role.pk
                    })
                }
                return {
                    ...role,
                    label: role.name,
                    value: role.pk,
                }
            }));
        }
    }, [props.roles])

    useEffect(() => {
        const garageList = props.garages;

        if (garageList) {
            setGarages(garageList.map((garage) => {
                if (edit && sessionStorage.getItem('garageGuids') && garage.pk === sessionStorage.getItem('garageGuids')) {
                    setValue('garage', {
                        label: garage.name,
                        value: garage.pk
                    })
                }
                return {
                    ...garage,
                    label: garage.name,
                    value: garage.pk,
                }
            }));
        } else {
            setGarages([])
        }
    }, [props.garages])

    const cancelProcess = () => {
        clearSessionStorages()
        props.history.goBack()
    }

    const userRegistrationProcess = (data) => {
        let orgsWhoWillHaveAccess = data.organizationsWhoWillHaveAccess
        let accessibleOrgGuids = []
        if (isSOlshareUser) {
            for (let i = 0; i < orgsWhoWillHaveAccess.length; i++) {
                accessibleOrgGuids.push(orgsWhoWillHaveAccess[i].value)
            }
        } else {
            accessibleOrgGuids.push(loggedInUsersOrg)
        }
        const payload = {
            ...data,
            organization_guid: isSOlshareUser? data.organisation.value:loggedInUsersOrg,
            role_guid: data.role.value,
            garage_guids: data.garage? [data.garage.value] : [],
            user_type: data.user_type && data.garage? data.user_type.value : undefined,
            organization_guids: accessibleOrgGuids
        }
        if (!data.email) {
            delete payload["email"]
        }
        delete payload["role"]
        delete payload["password_confirmation"]
        delete payload["organizationsWhoWillHaveAccess"]
        delete payload["organisation"]
        delete payload["garage"]
        props.CREATE_USER(payload)
    }

    const onSubmit = (data) => {
        if (edit) {
            let orgsWhoWillHaveAccess = data.organizationsWhoWillHaveAccess
            let accessibleOrgGuids = []
            if (isSOlshareUser) {
                for (let i = 0; i < orgsWhoWillHaveAccess.length; i++) {
                    accessibleOrgGuids.push(orgsWhoWillHaveAccess[i].value)
                }
            } else {
                accessibleOrgGuids.push(loggedInUsersOrg)
            }
            const payload = {
                ...data,
                username: userName,
                organization_guid: isSOlshareUser? data.organisation.value:loggedInUsersOrg,
                role_guid: data.role.value,
                garage_guids: data.garage? [data.garage.value] : [],
                user_type: data.user_type && data.garage? data.user_type.value : undefined,
                organization_guids: accessibleOrgGuids
            }
            props.EDIT_USER(payload, userName);
        } else {
            userRegistrationProcess(data)
        }
    }

    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Register user'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1>
                                <b>{edit ? (language === "EN" ? "Edit user" : "ব্যবহারকারীর তথ্য সম্পাদনা করুন") : (language === "EN" ? "Register user" : "ব্যবহারকারী নিবন্ধন করুন")}</b>
                            </h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Label>{language === 'EN' ? 'Name' : 'নাম'}<span
                                        className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="text"
                                        name={"name"}
                                        ref={register({
                                            required: language === 'EN' ? 'Required' : 'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === "EN" ? "Name of the user" : "ব্যবহারকারীর নাম"}
                                        autoComplete={"off"}
                                    />
                                    {errors.name && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.name.message}
                                    </div>}
                                    {errorMessage.name ? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.name}
                                    </div>:null}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Label>SOLshare Email</Form.Label>
                                    <SolInput
                                        type="email"
                                        name={"email"}
                                        ref={register({
                                            validate: {
                                                isValidSolshareEmail: value => (/^[A-Za-z0-9._%+-]+@solshare.com$/i.test(value) || !value) || "Invalid SOLshare email address!"
                                            }
                                        })}
                                        placeholder={language === "EN" ? "Enter SOLshare email" : "ব্যবহারকারীর নাম"}
                                        autoComplete={"off"}
                                    />
                                    {errors.email && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.email?.message}
                                    </div>}
                                    {errorMessage.email? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.email}
                                    </div>:null}
                                </Form.Group>
                            </div>
                        </div>

                        {
                            !edit && <div className={'row g-3'}>
                                <div className={"col-md-6"}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === "EN" ? "Username" : "লগইন করতে ব্যবহৃত আইডি"}<span
                                                className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'username'}
                                            ref={register({
                                                required: language === 'EN' ? 'Required' : 'উপরের তথ্যটি আবশ্যক!',
                                                validate: {
                                                    isValidEmail: (value) => {if (value && value.includes('@') && !isEmail(value)) {return "Username must be a valid email!"}},
                                                    isValidPhoneLength: (value) => {if (value && !value.includes('@') && value.length !== 11) {return "Username must be a 11 digit phone number!"}},
                                                    isValidPhone: (value) => {if (value && !value.includes('@') && !isValidPhoneNumber(value, 'BD')) {return "Username must be a valid phone number!"}}
                                                }
                                            })}
                                            placeholder={language === 'EN' ? 'Provide a valid phone number or valid email' : 'আইডি হিসেবে ব্যবহারের জন্য ফোন নাম্বার লিখুন। যেমনঃ 01676366473'}
                                            autoComplete={"off"}
                                        />
                                        {errors.username && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.username.message}
                                        </div>}
                                        {errorMessage.username ? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.username}
                                        </div> : null}
                                    </Form.Group>
                                </div>
                            </div>
                        }

                        {!edit && <div className={'row g-3'}>

                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === 'EN' ? 'Password' : 'পাসওয়ার্ড'}<span
                                            className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <SolInput
                                        name={'password'}
                                        type={"password"}
                                        ref={register({
                                            required: language === 'EN' ? 'Required' : 'উপরের তথ্যটি আবশ্যক!',
                                            validate: {
                                                isValidPass: value => (value.length >= 4 || !value) || 'Password must be of at least 4 characters!'
                                            }
                                        })}
                                        placeholder={language === 'EN' ? 'Password' : 'পাসওয়ার্ড'}
                                        autoComplete={"off"}
                                    />
                                    {errors.password && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.password.message}
                                    </div>}
                                    {errorMessage.password? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.password}
                                    </div>:null}
                                </Form.Group>
                            </div>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === 'EN' ? 'Password confirmation' : 'পাসওয়ার্ড নিশ্চিত করুন'}<span
                                            className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <SolInput
                                        name={'password_confirmation'}
                                        type={"password"}
                                        ref={register({
                                            required: language === 'EN' ? 'Required' : 'উপরের তথ্যটি আবশ্যক!',
                                            validate: {
                                                doesPasswordMatched: value => (getValues().password === value || !value) || 'Password does not match!'
                                            }
                                        })}
                                        placeholder={'Retype Password'}
                                        autoComplete={"off"}
                                    />
                                    {errors.password_confirmation && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.password_confirmation.message}
                                    </div>}
                                </Form.Group>
                            </div>
                        </div>}

                        <div className={'row g-3'}>
                            {isSOlshareUser ? <>
                                <div className={"col-md-6"}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN' ? 'Organisation' : 'প্রতিষ্ঠান'}<span
                                                className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"organisation"}
                                            rules={{ required: isSOlshareUser? "Required":false }}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN' ? 'Select an Organisation' : 'প্রতিষ্ঠান বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.orgListLoading}
                                                    isLoading={props.orgListLoading}
                                                    maxMenuHeight={200}
                                                    isClearable={true}
                                                    control={control}
                                                    value={value}
                                                    options={organisations}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => "No organizations available"}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setValue("garage", "")
                                                            setGarages([])
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setValue("garage", "")
                                                            GET_GARAGES({'organization_guid': selected.value})
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.organisation && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.organisation.message}
                                        </div>}
                                        {errorMessage.organisation? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.organisation}
                                        </div>:null}
                                    </Form.Group>
                                </div>
                            </>:null}
                        </div>

                        {isSOlshareUser ? <>
                            <div className={'row g-3'}>
                                <div className={"col-md-6"}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN' ? 'Access to' : 'তথ্য দেখতে পাবেন যেসব প্রতিষ্ঠানের'}<span
                                                className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"organizationsWhoWillHaveAccess"}
                                            rules={{ required: isSOlshareUser? "Required":false }}
                                            defaultValue={''}
                                            render={({onChange, onBlur, value, name, ref},
                                                     {invalid, isTouched, isDirty}) => (
                                                <Select
                                                    placeholder={language === 'EN' ? 'Select organisations...' : 'এক বা একাধিক প্রতিষ্ঠান বাছাই করুন...'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.orgListLoading}
                                                    isLoading={props.orgListLoading}
                                                    maxMenuHeight={200}
                                                    isClearable={true}
                                                    control={control}
                                                    value={value}
                                                    options={organisations}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    noOptionsMessage={() => "No organizations available"}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.organizationsWhoWillHaveAccess && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.organizationsWhoWillHaveAccess.message}
                                        </div>}
                                        {errorMessage.accessTo? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.accessTo}
                                        </div>:null}
                                    </Form.Group>
                                </div>
                            </div>
                        </>:null}

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === 'EN' ? 'Role' : 'ব্যবহারকারীর ধরন'}<span
                                            className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"role"}
                                        rules={{ required: "Required" }}
                                        defaultValue={''}
                                        render={({onChange, onBlur, value, name, ref},
                                                 {invalid, isTouched, isDirty}) => (
                                            <Select
                                                placeholder={language === 'EN' ? 'Select a Role' : 'ব্যবহারকারীর ধরন বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.roleListLoading}
                                                isLoading={props.roleListLoading}
                                                maxMenuHeight={200}
                                                isClearable={true}
                                                control={control}
                                                value={value}
                                                options={roles}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No roles available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.role && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.role.message}
                                    </div>}
                                    {errorMessage.role? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.role}
                                    </div>:null}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === 'EN' ? 'Garage' : 'গ্যারেজ'} {isSOlshareUser? <>
                                            <LightTooltip
                                                title={garageSelectionHelpText} placement="right" arrow componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        '& .MuiTooltip-arrow': {
                                                            color: 'common.white'
                                                        }
                                                    }
                                                }}} open={showGarageSelectionHelperTooltip}
                                                onOpen={() => setShowGarageSelectionHelperTooltip(true)}
                                                onClose={() => setShowGarageSelectionHelperTooltip(false)}>
                                                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                                                    if (isTabletOrMobile) {
                                                        setShowGarageSelectionHelperTooltip(!showGarageSelectionHelperTooltip)
                                                    }
                                                }}/>
                                            </LightTooltip>
                                        </>:''}</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"garage"}
                                        defaultValue={''}
                                        render={({onChange, onBlur, value, name, ref},
                                                 {invalid, isTouched, isDirty}) => (
                                            <Select
                                                placeholder={language === 'EN' ? 'Select a garage' : 'গ্যারেজ বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.garageListLoading}
                                                isLoading={props.garageListLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                options={garages}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No garages available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                    setValue("user_type", "")
                                                    clearErrors("user_type")
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.garage && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage.message}
                                    </div>}
                                    {errorMessage.garage ? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.garage}
                                    </div>:null}
                                </Form.Group>
                            </div>

                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label><b>{language === 'EN' ? 'User Type' : 'User Type'}</b></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"user_type"}
                                        rules={{ required: getValues().garage? "You have to select a user type for the selected garage!":false }}
                                        defaultValue={""}
                                        render={({onChange, onBlur, value, name, ref},
                                                 {invalid, isTouched, isDirty}) => (
                                            <Select
                                                placeholder={language === 'EN' ? 'Select a User Type' : 'Select a User Type'}
                                                classNamePrefix="react-select-sol-style"
                                                maxMenuHeight={200}
                                                isClearable={true}
                                                control={control}
                                                options={user_types}
                                                value={value}
                                                isSearchable={true}
                                                inputRef={ref}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.user_type && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.user_type.message}
                                    </div>}
                                </Form.Group>
                            </div>

                        </div>

                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Button variant="warning" size={'md'} type="submit"
                                        disabled={props.userInfoSubmitting || props.orgListLoading ||
                                            props.roleListLoading || props.garageListLoading}>
                                    {props.userInfoSubmitting? <><Spinner animation={'border'} size={'sm'}
                                                                                    variant={'light'}/> </> : null} {edit ? <>
                                    <i className='fa fa-check'
                                       aria-hidden='true'/>&nbsp;{language === 'EN' ? 'Save' : 'সংরক্ষণ করুন'}</> : <><i
                                    className='fa fa-registered'
                                    aria-hidden='true'/>&nbsp;{language === 'EN' ? 'Register' : 'নিবন্ধন করুন'}</>}
                                </Button>
                                <Button variant="dark" size={'md'} onClick={() => cancelProcess()} style={{
                                    backgroundColor: '#8C8C9B',
                                    outline: '#8C8C9B',
                                    border: '#8C8C9B',
                                }} disabled={props.userInfoSubmitting} className={"ml-3"}>
                                    <i className='fa fa-times'
                                       aria-hidden='true'/>&nbsp;{language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>

            </div>

            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active {
                border: 1px solid #F18D00;
                box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                outline: none;
              }

              .custom-popover {
                min-width: fit-content;
                min-height: fit-content;
              }
            `}</style>
        </ContentWrapper>
    );
};

CreateUser.propTypes = {};


const mapStateToProps = (state) => {

    return {
        language: state.auth.language,
        is_solshare_user: state.auth.is_solshare_user,
        organization_guid: state.auth.organization_guid,
        isLoading: state.usersReducer.isLoading,
        organisations: state.usersReducer.organisations,
        samList: state.usersReducer.samList,
        roles: state.usersReducer.roles,
        garages: state.usersReducer.garages,
        orgListLoading: state.usersReducer.orgListLoading,
        roleListLoading: state.usersReducer.roleListLoading,
        garageListLoading: state.usersReducer.garageListLoading,
        userCreated: state.usersReducer.userCreated,
        userUpdated: state.usersReducer.userUpdated,
        userDetails: state.usersReducer.userDetails,
        userInfoSubmitting: state.usersReducer.userInfoSubmitting,
        successMessage: state.usersReducer.successMessage,
        errorMessage: state.usersReducer.errorMessage,
        successMessageEdit: state.usersReducer.successMessageEdit,
        errorMessageEdit: state.usersReducer.errorMessageEdit,
        errorMessageRelatedToRoleListCollection: state.usersReducer.errorMessageRelatedToRoleListCollection,
        errorMessageForOrgCollection: state.usersReducer.errorMessageForOrgCollection,
        errorMessageForGarageCollection: state.usersReducer.errorMessageForGarageCollection
    }
}

export default connect(mapStateToProps, actions)(CreateUser);
