import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../actions";
import {actions as commonActions} from "../../../../commonReduxActions/actions";
import {withRoles} from "../../../../../router/SecuredRoute";
import {useMediaQuery} from "react-responsive";
import LastTransactions from "../reUsables/lastTransactions"
import LocationInGoogleMap from "./sections/locationInGoogleMap"
import {searchResultReducer} from "../../../reducer";
import {showNotifications} from "../../../../../utils/notification";
import BorrowerProfile from "../reUsables/borrowerProfile";
import EvDetails from "./sections/evDetails";
import LastRents from "./sections/lastRents";
import EventHistory from "./sections/eventHistory";
import {LoadingSpinner} from "../../../../../components";


const EvOrDriverSearch = ({searchParam, searchValue, ...props}) => {
    const [language, setLanguage] = useState(null);
    const [evDetails, setEvDetails] = useState(undefined);
    const [lastTransactions, setLastTransactions] = useState([]);
    const [rents, setLastRents] = useState([]);
    const [evGuid, setEvGuid] = useState('');
    const [lastRentInfo, setLastRentInfo] = useState({});
    const [mapData, setMapData] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [scrollerHeight, setScrollerHeight] = useState(0);
    const leftSideColumnOfTheEventHistory = useRef(null)
    const [mapWidth, setMapWidth] = useState(0);
    const [mapHeight, setMapHeight] = useState(0);

    const {
        COLLECT_EV_DETAILS,
        COLLECT_TRANSACTIONS,
        COLLECT_RENT_LIST,
        COLLECT_LOCATIONS,
        COLLECT_EVENTS_HISTORY
    } = props;

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    useEffect(() => {
        if (searchValue.pk) {
            COLLECT_EV_DETAILS({ev_guid: searchValue.pk})
            setEvGuid(searchValue.pk)
            COLLECT_TRANSACTIONS({ev_sl: searchParam, garage_guid: searchValue.garage_guid,
                organization_guid: searchValue.organization_guid, page_size: 5, page: 1})
        }
    }, [searchValue.pk]);

    useEffect(() => {
        if (props.evDetails) {
            const evDetails = props.evDetails
            setEvDetails(evDetails)
            COLLECT_RENT_LIST({ev_guid: searchValue.pk, garage_guid: searchValue.garage_guid,
                organization_guid: searchValue.organization_guid, page_size: 5, page: 1})
        } else {
            setEvDetails(undefined)
            setLastRentInfo({})
        }
    }, [props.evDetails])

    useEffect(() => {
        if (props.transactions) {
            const transactions = props.transactions.results
            setLastTransactions(transactions)
        }
    }, [props.transactions])

    useEffect(() => {
        if (props.rentList) {
            const rents = props.rentList.results
            setLastRentInfo(rents[0]? rents[0]:{})
            setLastRents(rents)
            COLLECT_LOCATIONS({ev_guid: searchValue.pk, limit: 1})
        }
    }, [props.rentList])

    useEffect(() => {
        if (props.locationData) {
            setMapData(props.locationData)
            if (leftSideColumnOfTheEventHistory && leftSideColumnOfTheEventHistory.current) {
                setScrollerHeight(leftSideColumnOfTheEventHistory.current.clientHeight + leftSideColumnOfTheEventHistory.current.clientHeight / 3)
                setMapWidth(leftSideColumnOfTheEventHistory.current.clientWidth)
                setMapHeight(leftSideColumnOfTheEventHistory.current.clientHeight / 3)
            }
            COLLECT_EVENTS_HISTORY({device_serial_number: evDetails.device_serial_number})
        }
    }, [props.locationData])

    useEffect(() => {
        if (props.errorMessageForEvDetailsCollection) {
            showNotifications('error', props.errorMessageForEvDetailsCollection)
            COLLECT_RENT_LIST({ev_guid: searchValue.pk, garage_guid: searchValue.garage_guid,
                organization_guid: searchValue.organization_guid, page_size: 5, page: 1})
        }
    }, [props.errorMessageForEvDetailsCollection])

    useEffect(() => {
        if (props.errorMessageForTransactionsCollection) {
            showNotifications('error', props.errorMessageForTransactionsCollection)
        }
    }, [props.errorMessageForTransactionsCollection])

    useEffect(() => {
        if (props.errorMessageForRentListCollection) {
            showNotifications('error', props.errorMessageForRentListCollection)
            COLLECT_LOCATIONS({ev_guid: searchValue.pk, limit: 1})
        }
    }, [props.errorMessageForRentListCollection])

    useEffect(() => {
        if (props.errorMessageForLocationDataCollection) {
            showNotifications('error', props.errorMessageForLocationDataCollection)
            if (leftSideColumnOfTheEventHistory && leftSideColumnOfTheEventHistory.current) {
                setScrollerHeight(leftSideColumnOfTheEventHistory.current.clientHeight)
            }
            COLLECT_EVENTS_HISTORY({device_serial_number: evDetails.device_serial_number})
        }
    }, [props.errorMessageForLocationDataCollection])

    return (
        <>
            {/* Always showing EV details at the top */}
            {props.collectingEvDetails && !evDetails? <>
                <div className={'row'} style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <LoadingSpinner
                        loadingSubText={'Collecting EV details...'}
                        language={'EN'} size={'lg'}/>
                </div>
            </>:<>
                {!props.collectingEvDetails && props.errorMessageForEvDetailsCollection? <>
                    <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>EV details can't be collected!</div>
                </>:<>
                    <EvDetails evNumber={searchParam} evData={evDetails} lastRentData={lastRentInfo}/>
                    {props.collectingTransactions? <>
                        <div className={'row'} style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                            <LoadingSpinner
                                loadingSubText={'Collecting latest transactions, rents and event histories...'}
                                language={'EN'} size={'lg'}/>
                        </div>
                    </>:<>
                        {props.collectingLocationData? <>
                            <div className={'row'}>
                                <div className={'col-lg-12'} ref={leftSideColumnOfTheEventHistory}>
                                    <LastTransactions transactionData={lastTransactions}/>
                                    <LastRents rentData={rents} evGuid={evGuid}/>
                                </div>
                            </div>
                            <div className={'row'} style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                <LoadingSpinner
                                    loadingSubText={'Collecting location information and event histories...'}
                                    language={'EN'} size={'lg'}/>
                            </div>
                        </>:<>
                            <>
                                <div className={'row'}>
                                    <div className={'col-lg-9'} ref={leftSideColumnOfTheEventHistory}>
                                        <LastTransactions transactionData={lastTransactions}/>
                                        <LastRents rentData={rents} evGuid={evGuid}/>
                                        <LocationInGoogleMap width={mapWidth} height={mapHeight}
                                                             locationInfo={mapData} evData={evDetails}
                                                             evSerial={searchParam}/>
                                    </div>
                                    <div className={'col-lg-3'}>
                                        <EventHistory scrollerHeight={scrollerHeight}/>
                                    </div>
                                </div>
                            </>
                        </>}
                    </>}
                </>}
            </>}
        </>
    );
};

EvOrDriverSearch.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        evDetails: state.searchResultReducer.evDetails,
        collectingEvDetails: state.searchResultReducer.collectingEvDetails,
        errorMessageForEvDetailsCollection: state.searchResultReducer.errorMessageForEvDetailsCollection,
        transactions: state.searchResultReducer.transactions,
        collectingTransactions: state.searchResultReducer.collectingTransactions,
        errorMessageForTransactionsCollection: state.searchResultReducer.errorMessageForTransactionsCollection,
        rentList: state.searchResultReducer.rentList,
        collectingRents: state.searchResultReducer.collectingRents,
        errorMessageForRentListCollection: state.searchResultReducer.errorMessageForRentListCollection,
        locationData: state.searchResultReducer.locationData,
        collectingLocationData: state.searchResultReducer.collectingLocationData,
        errorMessageForLocationDataCollection: state.searchResultReducer.errorMessageForLocationDataCollection,
        // Following code segment will be removed when location feature is made visible - Noor Reza, 24th June, 2024 3:09 PM
        events: state.commonReducer.events
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(EvOrDriverSearch));
