import React, {useEffect, useState, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {useMediaQuery} from "react-responsive";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Plot from "react-plotly.js";
import DataTable from "../../../../components/dataTable/DataTable";
import {LoadingSpinner} from "../../../../components";
import {DataTableContainer} from "../../../rentLog/utils";
import {Button} from "react-bootstrap";
import {showNotifications} from "../../../../utils/notification";
import Skeleton from '@mui/material/Skeleton';
import Stack from "@mui/material/Stack";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import '../customStyleForOverview.css';
import Calendar from "react-calendar";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


const ActivityOverview = ({activitiesTrend, inactiveBatteries, ...props}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [language, setLanguage] = useState("EN")

    // Activities graph
    // Sample data for following variable: ['1 Aug 2023', '2 Aug 2023', '3 Aug 2023', '4 Aug 2023', '5 Aug 2023', '6 Aug 2023', '7 Aug 2023', '8 Aug 2023', '9 Aug 2023', '10 Aug 2023', '11 Aug 2023']
    const [xAxisActivitiesGraph, setXAxisActivitiesGraph] = useState(undefined);
    // Sample data for following variable: [80, 72, 100, 110, 102, 110, 72, 100, 110, 102, 110]
    const [yAxisActivitiesGraph, setYAxisActivitiesGraph] = useState(undefined);
    // Sample data for following variable: [80, 72, 100, 110, 102, 110, 72, 100, 110, 102, 110]
    const [textForActivitiesGraph, setTextForActivitiesGraph] = useState(undefined);

    // Active days calendar
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [smartBatteryForActivitySummary, setSmartBatteryForActivitySummary] = useState('');
    const [monthOfActivitySummary, setMonthOfActivitySummary] = useState('');
    const [viewRelatedValueOfTheCalendar, setViewRelatedValueOfTheCalendar] = useState(new Date());
    const [activeDays, setActiveDays] = useState(new Set());
    const [inactiveDays, setInactiveDays] = useState(new Set());
    const [currentMonthIsSelected, setCurrentMonthIsSelected] = useState(true); // By default, current month is shown
    const [value, setCalenderValue] = useState();
    const calendarRef = useRef(null);
    // Saving 281 as initial value, as we have seen the height of the calendar div as 281 at the laptop screen
    const [heightOfTheCalendar, setHeightOfTheCalendar] = useState(281)

    // Line chart styling related
    const layout = {
        xaxis: {
            showgrid: false
        },
        yaxis: {
            showgrid: false
        },
        showlegend: false,
        autosize: true, // Ensures the chart adjusts its size dynamically
        hovermode: 'closest'
    }
    // Static data for the chart
    const dataForGraph = [
        {
            type: 'scatter',
            mode: 'lines+markers+text', // shows lines, markers, and text
            x: xAxisActivitiesGraph,
            y: yAxisActivitiesGraph,
            marker: {
                color: '#0B6E4F', // color of the markers
                size: 10, // size of the markers
                symbol: 'circle', // shape of the markers
            },
            line: {
                color: '#0B6E4F' // color of the line
            },
            text: textForActivitiesGraph, // text values to display at markers
            textposition: 'top center', // position of the text relative to markers
            textfont: {
                size: 12,
                color: 'black'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Date:</b> %{x}<br><b>Active Batteries:</b> %{y}<br><extra></extra>' // Custom tooltip content
        }
    ]

    // Inactive batteries table column
    const inactiveBatteriesTableColumns = [
        {
            field: 'serial_number',
            title: 'Smart Battery',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <h6><strong>SB-{rowData.serial_number}</strong></h6>
                    <small><strong>SOLdongle</strong></small><br/>
                    {rowData.dongle_details? <>
                        <small style={{whiteSpace: 'nowrap'}}><strong>#{rowData.dongle_serial_number},&nbsp;FW&nbsp;v{rowData.dongle_details.firmware_version},&nbsp;HW&nbsp;{rowData.dongle_details.hardware_version}</strong></small><br/>
                    </>:<>
                        <small style={{whiteSpace: 'nowrap'}}><strong>#{rowData.dongle_serial_number}</strong></small><br/>
                    </>}
                    <small><strong>Battery&nbsp;#{rowData.battery_serial_number}</strong></small><br/>
                    {rowData.battery_details? <>
                        {rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                            <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_voltage}V,&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                        </>:<>
                            {rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && !rowData.battery_details.nameplate_capacity? <>
                                <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_voltage}V</strong></small>
                            </>:<>
                                {rowData.battery_details.battery_vendor && !rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                    <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                                </>:<>
                                    {!rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                        <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.nameplate_voltage}V,&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                                    </>:<>
                                        {rowData.battery_details.battery_vendor && !rowData.battery_details.nameplate_voltage && !rowData.battery_details.nameplate_capacity? <>
                                            <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}</strong></small>
                                        </>:<>
                                            {!rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && !rowData.battery_details.nameplate_capacity? <>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.nameplate_voltage}V</strong></small>
                                            </>:<>
                                                {!rowData.battery_details.battery_vendor && !rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                                    <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                                                </>:''}
                                            </>}
                                        </>}
                                    </>}
                                </>}
                            </>}
                        </>}
                    </>:''}
                </>
            }
        },
        {
            field: 'garage_name',
            title: 'Borrower',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    {rowData.contract_details? <>
                        <p className="mb-0">{rowData.contract_details.borrower_name}</p>
                    </>:''}
                    <p className="mb-0">{rowData.garage_name}</p>
                </>
            }
        },
        {
            field: 'last_rent_created_at',
            title: 'Last Rented On',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return   <>
                    <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.last_rent_created_at).format("MMM D, YYYY hh:mma")}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: 'Activity Trend',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return   <>
                    <Button variant={'warning'} size={'sm'} onClick={() => {showActivityTrend(rowData)}}>View</Button>
                </>
            }
        }
    ]

    const {
        COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
    }, [])

    useEffect(() => {
        if (activitiesTrend) {
            if (activitiesTrend["date_wise_active_batteries"]) {
                let dateWiseActiveBatteries = activitiesTrend["date_wise_active_batteries"]
                let xAxisData = []
                let yAxisData = []
                let textLabelData = []
                for (let i=0; i< dateWiseActiveBatteries.length; i++) {
                    xAxisData.push(moment(dateWiseActiveBatteries[i]["date_of_processed_data"], "YYYY-MM-DD").format("DD MMM YYYY"))
                    yAxisData.push(dateWiseActiveBatteries[i]["number_of_active_batteries"])
                    textLabelData.push(new Intl.NumberFormat('en-IN').format(dateWiseActiveBatteries[i]["number_of_active_batteries"]))
                }

                setXAxisActivitiesGraph(xAxisData)
                setYAxisActivitiesGraph(yAxisData)
                setTextForActivitiesGraph(textLabelData)
            } else {
                setXAxisActivitiesGraph([])
                setYAxisActivitiesGraph([])
                setTextForActivitiesGraph([])
            }
        }
    },[activitiesTrend])

    const activeBatteryCard = () => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        Active Batteries
                    </Typography>
                    {props.collectingActiveBatteryCounts? <>
                        <Skeleton variant="rect" sx={{ marginTop: '10px', width: '100%' }} height={440}/>
                    </>:<>
                        <Plot
                            data={dataForGraph}
                            useResizeHandler={true} // This property makes the Plotly chart responsive to window resizing.
                            style={{width: '100%', height: '100%'}} // setting height to 100% has some effects that can't be noticed instantly
                            layout={{...layout}}
                            config={isTabletOrMobile? {
                                displayModeBar: false  // Make the mode bar (download, zoom etc. icons) inaccessible in mobile view, in desktop view it becomes visible when user scrolls over the graph
                            }:{}}
                        />
                    </>}
                </CardContent>
            </Card>
        </>
    }

    const inactiveBatteryCard = () => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        Inactive Batteries
                    </Typography>
                    {!props.collectingInactiveBatteriesInfo && inactiveBatteries? <>
                        <DataTableContainer>
                            <DataTable
                                language={props.language}
                                noDataAvailableMessageInEnglish={'No inactive batteries to show'}
                                columns={inactiveBatteriesTableColumns}
                                data={inactiveBatteries}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}/>
                        </DataTableContainer>
                    </> : <>
                        <Skeleton variant="rect" sx={{ marginTop: '10px', width: '100%' }} height={690}/>
                    </>}
                </CardContent>
            </Card>
        </>
    }

    // --------------- Activity Trend related ----------------- //
    const showActivityTrend = (data) => {
        setShowActivityModal(true)
        let deviceSerial = data.serial_number
        setSmartBatteryForActivitySummary(deviceSerial)
        let dateFrom = moment().startOf('month').format("YYYY-MM-DD")
        if (moment().format("DD") !== '01') {
            let dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD")
            setMonthOfActivitySummary(moment(dateFrom, "YYYY-MM-DD").format("MMM, YYYY"))
            COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY(deviceSerial, {'date_from': dateFrom, 'date_to': dateTo})
        }
    }

    const hideActivityTrend = () => {
        setShowActivityModal(false)
        setViewRelatedValueOfTheCalendar(new Date())
        setSmartBatteryForActivitySummary('')
    }

    const onCalenderViewUpdate = (action, view, startDateOfTheCalendar) => {
        if (['next', 'prev', 'drillDown'].includes(action) && view === 'month') {
            let dateFrom = moment(startDateOfTheCalendar).format("YYYY-MM-DD")
            let dateTo = ''
            if (moment().format("MM") === moment(startDateOfTheCalendar).format("MM")) {
                // User has selected the current month
                dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD")
                setCurrentMonthIsSelected(true)
            } else {
                dateTo = moment(startDateOfTheCalendar).endOf('month').format("YYYY-MM-DD")
                setCurrentMonthIsSelected(false)
            }
            if (moment().format("DD") !== '01') {
                setMonthOfActivitySummary(moment(dateFrom, "YYYY-MM-DD").format("MMM, YYYY"))
                COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY(smartBatteryForActivitySummary, {
                    'date_from': dateFrom,
                    'date_to': dateTo
                })
            }
            setViewRelatedValueOfTheCalendar(startDateOfTheCalendar)
        } else if (view === 'year') {
            setViewRelatedValueOfTheCalendar(startDateOfTheCalendar)
        }
    }

    useEffect(() => {
        if (props.activitiesOfTheSB) {
            const activityInfo = props.activitiesOfTheSB
            setActiveDays(new Set(activityInfo.active_days))
            setInactiveDays(new Set(activityInfo.inactive_days))
            if (calendarRef.current) {
                setHeightOfTheCalendar(document.getElementsByClassName('calendar')[0].clientHeight)
                let daysText = activityInfo.active_days_total > 1 ? 'days' : 'day'
                let activeDaysWholeText = activityInfo.active_days_total > 0 || activityInfo.inactive_days_total > 0 ?
                    'Active for ' + activityInfo.active_days_total + ' ' + daysText : 'No data available'
                let actualHeader = document.getElementsByClassName('react-calendar__navigation__label__labelText')[0].innerText
                // As two API calls are happening due to usage of 'onViewChange' and 'onActiveStartDateChange'
                // Hence, we are preventing loading two p tags
                // We have tried to prevent it by excluding 'onViewChange' but it was generating an error (First month of a year selection was not being caught by 'onActiveStartDateChange')
                if (document.querySelector('span.react-calendar__navigation__label__labelText p') === null) {
                    document.getElementsByClassName('react-calendar__navigation__label__labelText')[0].innerHTML = '<p>' + actualHeader +
                        '</p>' + '<p style="font-size: 70% !important;">' + activeDaysWholeText + '</p>'
                }
                if (currentMonthIsSelected) {
                    let allWeekDaysElements = document.getElementsByClassName('react-calendar__month-view__days__day--weekend')
                    for (let i = 0; i < allWeekDaysElements.length; i++) {
                        if (allWeekDaysElements[i].hasAttribute('disabled')) {
                            let styleValues = allWeekDaysElements[i].getAttribute('style')
                            styleValues += 'color: #6d6d6d !important'
                            allWeekDaysElements[i].setAttribute('style', styleValues)
                        }
                    }
                }
            }
        }
    }, [props.activitiesOfTheSB])

    const currentDate = moment().format("YYYY-MM-DD")
    const renderActiveDaysDetailsModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideActivityTrend}
                centered={false}
                modal={showActivityModal}
                title={<>
                    <h1><strong>Smart Battery #{smartBatteryForActivitySummary} - Activity Trend</strong></h1>
                </>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        {props.collectingSBActivityCollection ? <Typography variant="h5">
                                <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={heightOfTheCalendar}>
                                    Collecting activities of {monthOfActivitySummary}
                                </Skeleton>
                            </Typography> :
                            <Stack justifyContent="center" alignItems="center" spacing={3}>
                                <div className={"calendar"}>
                                    <Calendar
                                        onChange={setCalenderValue}
                                        activeStartDate={viewRelatedValueOfTheCalendar}
                                        onActiveStartDateChange={(actionObject) => {
                                            onCalenderViewUpdate(actionObject.action, actionObject.view, actionObject.activeStartDate)
                                        }}
                                        onViewChange={(actionObject) => {
                                            onCalenderViewUpdate(actionObject.action, actionObject.view, actionObject.activeStartDate)
                                        }}
                                        value={value}
                                        tileClassName={({date, view}) => {
                                            if (view === 'month') {
                                                if (currentDate === moment(date).format("YYYY-MM-DD")) {
                                                    return 'bold'
                                                } else if (activeDays.has(moment(date).format("YYYY-MM-DD"))) {
                                                    return 'highlight'
                                                } else if (inactiveDays.has(moment(date).format("YYYY-MM-DD"))) {
                                                    return 'inActiveDays'
                                                }
                                            }
                                        }}
                                        maxDate={new Date()}
                                        nextLabel={<ArrowForwardIosIcon/>}
                                        prevLabel={<ArrowBackIosIcon/>}
                                        calendarType={"hebrew"}
                                        ref={calendarRef}
                                    />
                                    <Stack direction="row"
                                           justifyContent="center"
                                           alignItems="center"
                                           spacing={2}>
                                        <span style={{color: '#0b6e4f'}}><div className={'custom-box green'}></div>
                                            &nbsp;Rented</span>
                                        <span style={{color: '#cb152b'}}><div className={'custom-box red'}></div>
                                            &nbsp;Inactive</span>
                                    </Stack>
                                </div>
                            </Stack>}
                    </div>
                </div>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.errorMessageForSBActivityCollection) {
            showNotifications('error', props.errorMessageForSBActivityCollection)
        }
    }, [props.errorMessageForSBActivityCollection])

    return (
        <>
            <div className={'row'}>
                <div className={isTabletOrMobile? 'col-md-12':'col-md-12 pl-0'}>
                    {activeBatteryCard()}
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={isTabletOrMobile? 'col-md-12':'col-md-12 pl-0'}>
                    {inactiveBatteryCard()}
                </div>
            </div>
            {renderActiveDaysDetailsModal()}
        </>
    );
}

ActivityOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingActiveBatteryCounts: state.overviewReducer.collectingActiveBatteryCounts,
        collectingInactiveBatteriesInfo: state.overviewReducer.collectingInactiveBatteriesInfo,
        activitiesOfTheSB: state.overviewReducer.activitiesOfTheSB,
        errorMessageForSBActivityCollection: state.overviewReducer.errorMessageForSBActivityCollection,
        collectingSBActivityCollection: state.overviewReducer.collectingSBActivityCollection
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(ActivityOverview))
