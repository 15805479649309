export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST: 'COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST',
    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS: 'COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS',
    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE: 'COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE',

    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST: 'COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST',
    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS: 'COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS',
    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE: 'COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE',

    COLLECT_ZONE_AREA_OF_A_FINANCIER_REQUEST: 'COLLECT_ZONE_AREA_OF_A_FINANCIER_REQUEST',
    COLLECT_ZONE_AREA_OF_A_FINANCIER_SUCCESS: 'COLLECT_ZONE_AREA_OF_A_FINANCIER_SUCCESS',
    COLLECT_ZONE_AREA_OF_A_FINANCIER_FAILURE: 'COLLECT_ZONE_AREA_OF_A_FINANCIER_FAILURE',

    COLLECT_AT_A_GLANCE_INFO_REQUEST: 'COLLECT_AT_A_GLANCE_INFO_REQUEST',
    COLLECT_AT_A_GLANCE_INFO_SUCCESS: 'COLLECT_AT_A_GLANCE_INFO_SUCCESS',
    COLLECT_AT_A_GLANCE_INFO_FAILURE: 'COLLECT_AT_A_GLANCE_INFO_FAILURE',

    COLLECT_BRANCH_WISE_PERFORMANCE_INFO_REQUEST: 'COLLECT_BRANCH_WISE_PERFORMANCE_INFO_REQUEST',
    COLLECT_BRANCH_WISE_PERFORMANCE_INFO_SUCCESS: 'COLLECT_BRANCH_WISE_PERFORMANCE_INFO_SUCCESS',
    COLLECT_BRANCH_WISE_PERFORMANCE_INFO_FAILURE: 'COLLECT_BRANCH_WISE_PERFORMANCE_INFO_FAILURE',

    COLLECT_LOW_PERFORMING_BORROWERS_REQUEST: 'COLLECT_LOW_PERFORMING_BORROWERS_REQUEST',
    COLLECT_LOW_PERFORMING_BORROWERS_SUCCESS: 'COLLECT_LOW_PERFORMING_BORROWERS_SUCCESS',
    COLLECT_LOW_PERFORMING_BORROWERS_FAILURE: 'COLLECT_LOW_PERFORMING_BORROWERS_FAILURE',

    COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_REQUEST: 'COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_REQUEST',
    COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_SUCCESS: 'COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_SUCCESS',
    COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_FAILURE: 'COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_FAILURE',

    COLLECT_INACTIVE_BATTERIES_REQUEST: 'COLLECT_INACTIVE_BATTERIES_REQUEST',
    COLLECT_INACTIVE_BATTERIES_SUCCESS: 'COLLECT_INACTIVE_BATTERIES_SUCCESS',
    COLLECT_INACTIVE_BATTERIES_FAILURE: 'COLLECT_INACTIVE_BATTERIES_FAILURE',

    COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_REQUEST: 'COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_REQUEST',
    COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_SUCCESS: 'COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_SUCCESS',
    COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_FAILURE: 'COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
};
