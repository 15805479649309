import React, {useEffect, useState, useRef} from 'react';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../_metronic/layout";
import Stack from "@mui/material/Stack";
import moment from "moment";
import {actions} from "../actions";
import { actions as commonActions } from '../../commonReduxActions/actions';
import {withRoles} from "../../../router/SecuredRoute";
import SummaryInfoForOverview from "./summaryInfo/summaryInfo";
import PerformanceInfoForOverview from "./performanceInfo/performanceInfo";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from "@mui/material/styles";
import {useMediaQuery} from "react-responsive";
import {LoadingSpinner, SolInput} from "../../../components";
import {showNotifications} from "../../../utils/notification";
import {toast} from "react-toastify";
import {checkAuthorization} from "../../auth/authorization";
import {getServiceName} from "../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../utils/roleRelatedValues/actionNames";
import {Toolbar} from "../../organizations/views/components";
import {DataTableContainer} from "../../organizations/utils";
import DataTable from "../../../components/dataTable/DataTable";
import {organizationListColumns} from "../../organizations/views/organizationList/organizationListColumns";
// import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {DataTableContainerForLoanDetails} from "../../contractManagement/utils";
import Skeleton from "@mui/material/Skeleton";
import {Button, Form, Popover} from "react-bootstrap";
import Select from "react-select";
import {customStylesForMultiSelectField} from "../../../utils/reactSelectRelated/customStylesForMultiSelect";
import {ValueContainer} from "../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {groupSelectedOptions} from "../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect";
import {formulateTextFromSelectedItems} from "../../../utils/multiSelectValueFormation/formatForExportedFile";
import {formulateApiRequestDataFromSelectedItems} from "../../../utils/multiSelectValueFormation/formatForApiRequest";
import {customStylesForSingleSelectField} from "../../../utils/reactSelectRelated/customStylesForSingleSelect";
import Typography from "@mui/material/Typography";
import AtAGlanceOverview from "./sections/atAGlance";
import BranchWisePerformanceOverview from "./sections/branchWisePerformance";
import BorrowerWisePerformanceOverview from "./sections/borrowerWisePerformance";
import ActivityOverview from "./sections/activity";
import {useForm} from "react-hook-form";
import Overlay from "react-bootstrap/Overlay";
import {DayPickerRangeController} from "react-dates";
import InfoIcon from "@mui/icons-material/Info";
import {overviewReducer} from "../reducer";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import {subtractExactOneMonth} from "../../../utils/monthAdditionSubtraction/exactDateAfterSubtractingOneMonth";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";


const UpdatedOverview = props => {
    const { handleSubmit, register} = useForm()
    const subHeader = useSubheader();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: '0.875rem',
            fontWeight: 400
        }
    }));

    // Tab styling related
    const useStyles = makeStyles((theme) => ({
        tab: {
            '&:hover': {
                color: '#F18D00'
            },
            '&:focus': {
                color: '#F18D00'
            },
            '&.Mui-selected': {
                color: '#F18D00'
            },
            color: '#8C8C9B',
            textTransform: 'none',
            ...theme.typography.h5
        },
        indicator: {
            backgroundColor: '#F18D00'
        }
    }));
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const TabPanel = ({...props}) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}
                 {...other}>
                {value === index && (
                    <Box p={2}>{children}</Box>
                )}
            </div>
        );
    }

    // Filter related
    const [financiers, setFinanciers] = useState([]);
    const [financier, setFinancier] = useState('');
    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState('');
    const [areas, setAreas] = useState([]);
    const [area, setArea] = useState('');
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState('');
    const [garages, setGarages] = useState([]);
    const [garage, setGarage] = useState('');
    const [targetForOverviewDateRangePicker, setTargetForOverviewDateRangePicker] = useState(null);
    const [showOverviewDateRangePicker, setShowOverviewDateRangePicker] = useState(false);
    const [overviewDateRangeStart, setOverviewDateRangeStart] = useState(null);
    const [overviewDateRangeEnd, setOverviewDateRangeEnd] = useState(null);
    const [overviewDateRange, setOverviewDateRange] = useState('');
    const [focusedInputForOverviewDate, setFocusedInputForOverviewDate] = useState('startDate');
    const [filterParams, setFilterParams] = useState({});

    const zoneToolTipText = 'Only zones under the selected financiers will be available for selection'
    const [showZoneFieldTooltip, setShowZoneFieldTooltip] = useState(false);
    const toolTipForZoneField = () => {
        return <>
            <LightTooltip
                title={zoneToolTipText} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showZoneFieldTooltip}
                onOpen={() => setShowZoneFieldTooltip(true)}
                onClose={() => setShowZoneFieldTooltip(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowZoneFieldTooltip(!showZoneFieldTooltip)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    const areaToolTipText = 'Only areas under the selected zones will be available for selection'
    const [showAreaFieldTooltip, setShowAreaFieldTooltip] = useState(false);
    const toolTipForAreaField = () => {
        return <>
            <LightTooltip
                title={areaToolTipText} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showAreaFieldTooltip}
                onOpen={() => setShowAreaFieldTooltip(true)}
                onClose={() => setShowAreaFieldTooltip(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowAreaFieldTooltip(!showAreaFieldTooltip)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    const branchToolTipText = 'Only branches under the selected financiers will be available for selection'
    const [showBranchFieldTooltip, setShowBranchFieldTooltip] = useState(false);
    const toolTipForBranchField = () => {
        return <>
            <LightTooltip
                title={branchToolTipText} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showBranchFieldTooltip}
                onOpen={() => setShowBranchFieldTooltip(true)}
                onClose={() => setShowBranchFieldTooltip(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowBranchFieldTooltip(!showBranchFieldTooltip)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    // At a glance related
    const [atAGlanceInformation, setAtAGlanceInformation] = useState(undefined);
    const [overviewDataFromAnotherSource, setOverviewDataFromAnotherSource] = useState(undefined);

    // Branch-wise performance
    const [branchWisePerformanceInfo, setBranchWisePerformanceInfo] = useState(undefined);

    // Low performing borrowers
    const [lowPerformingBorrowersInfo, setLowPerformingBorrowersInfo] = useState(undefined);

    // Activity related
    const [batteryActivitiesTrend, setBatteryActivitiesTrend] = useState(undefined);
    const [inactiveBatteries, setInactiveBatteries] = useState(undefined);


    const {
        GET_ORGANISATIONS,
        COLLECT_ZONE_AREA_OF_A_FINANCIER,
        GET_BRANCHES_LIST,
        GET_GARAGE_LIST,
        COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE,
        COLLECT_AT_A_GLANCE_INFO,
        COLLECT_BRANCH_WISE_PERFORMANCE_INFO,
        COLLECT_LOW_PERFORMING_BORROWERS,
        COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND,
        COLLECT_INACTIVE_BATTERIES,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props;

    useEffect(() => {
        subHeader.setActionButtons(<strong>{moment().format("MMMM DD, YYYY / hh:mm a")}</strong>)
        subHeader.setBreadCrumbComponent(null)

        // Filter options collection
        if(props.isSolshareUser) {
            GET_ORGANISATIONS()
        }
        GET_GARAGE_LIST()

        // Calculating last one months date range
        let todaysDateTime = moment()
        let oneMonthEarlier = subtractExactOneMonth(moment(todaysDateTime)).add(1, 'days')
        setOverviewDateRangeStart(oneMonthEarlier)
        setOverviewDateRangeEnd(todaysDateTime)
        let initialFilter =  { from_date: moment(oneMonthEarlier).format("YYYY-MM-DD"),
            to_date: moment(todaysDateTime).format("YYYY-MM-DD") }
        setFilterParams(initialFilter)
        COLLECT_AT_A_GLANCE_INFO(initialFilter)
        COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE(initialFilter)

        return () => {
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    },[])

    // ------- Begin: Filter related -------- //
    useEffect(() => {
        if (props.organisations && props.organisations.length > -1) {
            setFinanciers(props.organisations.map((financier) => {
                return {
                    ...financier,
                    label: financier.name,
                    value: financier.pk
                }
            }))
        } else {
            setFinanciers([])
        }
    },[props.organisations])

    useEffect(() => {
        if (props.zoneAreaInfo && props.zoneAreaInfo.length > -1) {
            // TODO: Save the zone areas here
            // setFinanciers(props.organisations.map((financier) => {
            //     return {
            //         ...financier,
            //         label: financier.name,
            //         value: financier.pk
            //     }
            // }))
        } else {
            setZones([])
            setAreas([])
        }
    },[props.zoneAreaInfo])

    useEffect(() => {
        if (props.zoneAreaInfoCollectionError) {
            showNotifications('error', props.zoneAreaInfoCollectionError)
        }
    }, [props.zoneAreaInfoCollectionError])

    useEffect(() => {
        if (props.branches && props.branches.length > -1) {
            setBranches(props.branches.map((branch) => {
                return {
                    ...branch,
                    value: branch.pk,
                    label: branch.name
                }
            }))
        } else {
            setBranches([])
        }
    }, [props.branches])

    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            setGarages(props.garages.map((garage) => {
                return {
                    ...garage,
                    value: garage.pk,
                    label: garage.name
                }
            }))
        } else {
            setGarages([])
        }
    }, [props.garages])

    useEffect(() => {
        if (props.errorMessageForGarageListCollection) {
            showNotifications('error', props.errorMessageForGarageListCollection)
        }
    }, [props.errorMessageForGarageListCollection])

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }
    const [clickCountForOverviewDateRangePicker, setClickCountForOverviewDateRangePicker] = useState(0)
    const onDatesChange = ({startDate, endDate}) => {
        setClickCountForOverviewDateRangePicker(clickCountForOverviewDateRangePicker + 1)
        if (startDate && endDate) {
            setOverviewDateRangeStart(startDate)
            setOverviewDateRangeEnd(endDate)
            if (clickCountForOverviewDateRangePicker === 1) {
                setClickCountForOverviewDateRangePicker(0)
                setShowOverviewDateRangePicker(false)
            }
        } else if (startDate && !endDate) {
            setOverviewDateRangeStart(startDate)
            setOverviewDateRangeEnd(startDate)
        }
    }
    const handleFocusChangeForOverviewDate = (input) => {
        if (!input) {
            setFocusedInputForOverviewDate('startDate')
        } else {
            setFocusedInputForOverviewDate(input)
        }
    }
    const disableDates = (current) => {
        const today = moment()
        if (current.isAfter(today, 'day')) {
            return true
        }
        return false
    }
    useEffect(() => {
        setOverviewDateRange(overviewDateRangeStart && overviewDateRangeEnd ? `${moment(overviewDateRangeStart).format("DD MMM, YYYY")} - ${moment(overviewDateRangeEnd).format("DD MMM, YYYY")}` : '');
    }, [overviewDateRangeStart, overviewDateRangeEnd])
    const overviewDateRangePopover = () => {
        return <>
            <Overlay
                show={showOverviewDateRangePicker}
                target={targetForOverviewDateRangePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={overviewDateRangeStart}
                            endDate={overviewDateRangeEnd}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInputForOverviewDate}
                            onFocusChange={(focusedInput) => handleFocusChangeForOverviewDate(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            isOutsideRange={disableDates}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "date") {
                                    setShowOverviewDateRangePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(filterOverviewData)}>
                <div className={'row g-3 mt-4'}>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Date</Form.Label>
                            </div>
                            <SolInput
                                name={'date'}
                                readOnly={true}
                                value={overviewDateRange}
                                placeholder={"Select a date range"}
                                onClick={(e) => {
                                    setTargetForOverviewDateRangePicker(e.target)
                                    setShowOverviewDateRangePicker(!showOverviewDateRangePicker)
                                }}
                            />
                        </Form.Group>
                    </div>
                    {props.isSolshareUser? <>
                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Financier</Form.Label>
                                </div>
                                <Select
                                    placeholder={props.orgListLoading? 'Loading options..':'Select financiers'}
                                    name={'financier'} // This name is used to show 'n items selected' text
                                    classNamePrefix="react-select-sol-style"
                                    isDisabled={props.orgListLoading}
                                    isLoading={props.orgListLoading}
                                    maxMenuHeight={200}
                                    isClearable={true}
                                    options={financiers}
                                    value={financier}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    menuPortalTarget={document.querySelector('body')} // This is added to overflow the menu over the parent card
                                    noOptionsMessage={() => "No financiers available"}
                                    onChange={(selected) => {
                                        setBranch('')
                                        if (selected) {
                                            setFinancier(selected)
                                            setFinanciers(groupSelectedOptions(selected, financiers))

                                            // setZone('')
                                            // setArea('')
                                            // TODO: Collect the zone areas here
                                            // COLLECT_ZONE_AREA_OF_A_FINANCIER(selected[0].pk)

                                            GET_BRANCHES_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})

                                            GET_GARAGE_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})
                                        } else {
                                            setFinancier('')

                                            // setZone('')
                                            // setZones([])
                                            // setArea('')
                                            // setAreas([])

                                            setBranches([])

                                            GET_GARAGE_LIST()
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </>:''}
                    {/*<div className={'col-md-2'}>*/}
                    {/*    <Form.Group>*/}
                    {/*        <div>*/}
                    {/*            <Form.Label>Zone / Region{props.isSolshareUser? <>&nbsp;{toolTipForZoneField()}</>:''}</Form.Label>*/}
                    {/*        </div>*/}
                    {/*        <Select*/}
                    {/*            placeholder={props.collectingZoneAreaInfo? 'Loading options...':'Select zones'}*/}
                    {/*            name={'zone'} // This name is used to show 'n items selected' text*/}
                    {/*            classNamePrefix="react-select-sol-style"*/}
                    {/*            isDisabled={props.collectingZoneAreaInfo}*/}
                    {/*            isLoading={props.collectingZoneAreaInfo}*/}
                    {/*            maxMenuHeight={200}*/}
                    {/*            value={zone}*/}
                    {/*            isClearable={true}*/}
                    {/*            options={zones}*/}
                    {/*            isSearchable={true}*/}
                    {/*            isMulti={true}*/}
                    {/*            hideSelectedOptions={false}*/}
                    {/*            styles={customStylesForMultiSelectField}*/}
                    {/*            components={{ ValueContainer, Option }}*/}
                    {/*            menuPortalTarget={document.querySelector('body')} // This is added to overflow the menu over the parent card*/}
                    {/*            noOptionsMessage={() => "No zones available"}*/}
                    {/*            onChange={(selected) => {*/}
                    {/*                if (selected) {*/}
                    {/*                    // setGarage(selected)*/}
                    {/*                    // setGarages(groupSelectedOptions(selected, garages))*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</div>*/}
                    {/*<div className={'col-md-2'}>*/}
                    {/*    <Form.Group>*/}
                    {/*        <div>*/}
                    {/*            <Form.Label>Area&nbsp;{toolTipForAreaField()}</Form.Label>*/}
                    {/*        </div>*/}
                    {/*        <Select*/}
                    {/*            placeholder={props.collectingZoneAreaInfo? 'Loading options...':'Select areas'}*/}
                    {/*            name={'area'} // This name is used to show 'n items selected' text*/}
                    {/*            classNamePrefix="react-select-sol-style"*/}
                    {/*            isDisabled={props.collectingZoneAreaInfo}*/}
                    {/*            isLoading={props.collectingZoneAreaInfo}*/}
                    {/*            maxMenuHeight={200}*/}
                    {/*            value={area}*/}
                    {/*            isClearable={true}*/}
                    {/*            options={areas}*/}
                    {/*            isSearchable={true}*/}
                    {/*            isMulti={true}*/}
                    {/*            hideSelectedOptions={false}*/}
                    {/*            styles={customStylesForMultiSelectField}*/}
                    {/*            components={{ ValueContainer, Option }}*/}
                    {/*            menuPortalTarget={document.querySelector('body')} // This is added to overflow the menu over the parent card*/}
                    {/*            noOptionsMessage={() => "No areas available"}*/}
                    {/*            onChange={(selected) => {*/}
                    {/*                if (selected) {*/}
                    {/*                    // setGarage(selected)*/}
                    {/*                    // setGarages(groupSelectedOptions(selected, garages))*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</div>*/}
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Branch{props.isSolshareUser? <>&nbsp;{toolTipForBranchField()}</>:''}</Form.Label>
                            </div>
                            <Select
                                placeholder={props.collectingBranches? 'Loading options..':'Select branches'}
                                name={'branch'} // This name is used to show 'n items selected' text
                                classNamePrefix="react-select-sol-style"
                                isDisabled={props.collectingBranches}
                                isLoading={props.collectingBranches}
                                maxMenuHeight={200}
                                isClearable={true}
                                options={branches}
                                value={branch}
                                isSearchable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                styles={customStylesForMultiSelectField}
                                components={{ ValueContainer, Option }}
                                menuPortalTarget={document.querySelector('body')} // This is added to overflow the menu over the parent card
                                noOptionsMessage={() => "No branches available"}
                                onChange={(selected) => {
                                    if (selected) {
                                        setBranch(selected)
                                        setBranches(groupSelectedOptions(selected, branches))
                                    } else {
                                        setBranch('')
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Garage</Form.Label>
                            </div>
                            <Select
                                placeholder={props.collectingGarages? 'Loading options..':'Select garages'}
                                name={'garage'} // This name is used to show 'n items selected' text
                                classNamePrefix="react-select-sol-style"
                                isDisabled={props.collectingGarages}
                                isLoading={props.collectingGarages}
                                maxMenuHeight={200}
                                isClearable={true}
                                options={garages}
                                value={garage}
                                isSearchable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                styles={customStylesForMultiSelectField}
                                components={{ ValueContainer, Option }}
                                menuPortalTarget={document.querySelector('body')} // This is added to overflow the menu over the parent card
                                noOptionsMessage={() => "No garages available"}
                                onChange={(selected) => {
                                    if (selected) {
                                        setGarage(selected)
                                        setGarages(groupSelectedOptions(selected, garages))
                                    } else {
                                        setGarage('')
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="warning" size={'sm'} type="submit"
                                disabled={props.collectingAtAGlanceInfo || props.collectingBranchPerformanceInfo ||
                                    props.collectingLowPerformingBorrowers || props.collectingActiveBatteryCounts ||
                                    props.collectingInactiveBatteriesInfo || props.collectingSummaryDataFromGarage}>
                            Apply filter
                        </Button>
                        <Button variant="outline-dark" size={'sm'}
                                disabled={props.collectingAtAGlanceInfo || props.collectingBranchPerformanceInfo ||
                                    props.collectingLowPerformingBorrowers || props.collectingActiveBatteryCounts ||
                                    props.collectingInactiveBatteriesInfo || props.collectingSummaryDataFromGarage}
                                onClick={resetFilter} className={'ml-3'}>
                            Reset filter
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    }

    const filterOverviewData = (data) => {
        let filterCriteria = {}
        if (overviewDateRangeStart && overviewDateRangeEnd) {
            filterCriteria['from_date'] = overviewDateRangeStart.format("YYYY-MM-DD")
            filterCriteria['to_date'] = overviewDateRangeEnd.format("YYYY-MM-DD")
        }
        if (financier) {
            filterCriteria['financier_guid'] = formulateApiRequestDataFromSelectedItems(financier)
        }
        if (branch) {
            filterCriteria['branch_guid'] = formulateApiRequestDataFromSelectedItems(branch)
        }
        if (garage) {
            filterCriteria['garage_guid'] = formulateApiRequestDataFromSelectedItems(garage)
        }

        if (tabValue === 0) {
            // At a Glance
            COLLECT_AT_A_GLANCE_INFO(filterCriteria)
            COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE(filterCriteria)
        } else if (tabValue === 1) {
            COLLECT_BRANCH_WISE_PERFORMANCE_INFO(filterCriteria)
        } else if (tabValue === 2) {
            COLLECT_LOW_PERFORMING_BORROWERS(filterCriteria)
        } else {
            // Activity Trend
            COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND(filterCriteria)
            COLLECT_INACTIVE_BATTERIES(filterCriteria)
        }
        setFilterParams(filterCriteria)
    }

    const resetFilter = () => {
        setZone('')
        setArea('')
        setBranch('')
        setGarage('')

        if (props.isSolshareUser && financier) {
            setFinancier('')
            GET_BRANCHES_LIST()
        }

        // Making last one month selected at the "Date" field and collect last one month's data for the current selected tab/section
        let todaysDateTime = moment()
        let oneMonthEarlier = subtractExactOneMonth(moment(todaysDateTime)).add(1, 'days')
        setOverviewDateRangeStart(oneMonthEarlier)
        setOverviewDateRangeEnd(todaysDateTime)
        let initialFilter =  { from_date: moment(oneMonthEarlier).format("YYYY-MM-DD"),
            to_date: moment(todaysDateTime).format("YYYY-MM-DD") }
        setFilterParams(initialFilter)

        if (tabValue === 0) {
            // At a Glance
            COLLECT_AT_A_GLANCE_INFO(initialFilter)
            COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE(initialFilter)
        } else if (tabValue === 1) {
            COLLECT_BRANCH_WISE_PERFORMANCE_INFO(initialFilter)
        } else if (tabValue === 2) {
            COLLECT_LOW_PERFORMING_BORROWERS(initialFilter)
        } else {
            // Activity Trend
            COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND(initialFilter)
            COLLECT_INACTIVE_BATTERIES(initialFilter)
        }
    }
    // ------- End: Filter related -------- //


    // ------- Begin: Overview page data related -------- //
    // At a glance
    useEffect(() => {
        if (props.atAGlanceInfo) {
            setAtAGlanceInformation(props.atAGlanceInfo)
        } else {
            setAtAGlanceInformation(undefined)
        }
    },[props.atAGlanceInfo])
    useEffect(() => {
        if (props.atAGlanceInfoCollectionError) {
            showNotifications('error', props.atAGlanceInfoCollectionError)
        }
    }, [props.atAGlanceInfoCollectionError])

    // Some info for the "At a glance" page
    useEffect(() => {
        if (props.summaryDataFromGarage) {
            setOverviewDataFromAnotherSource(props.summaryDataFromGarage)
        } else {
            setOverviewDataFromAnotherSource(undefined)
        }
    },[props.summaryDataFromGarage])
    useEffect(() => {
        if (props.summaryDataCollectionErrorFromGarage) {
            showNotifications('error', props.summaryDataCollectionErrorFromGarage)
        }
    }, [props.summaryDataCollectionErrorFromGarage])

    // Branch-wise performance
    useEffect(() => {
        if (props.branchPerformanceInfo) {
            setBranchWisePerformanceInfo(props.branchPerformanceInfo)
        } else {
            setBranchWisePerformanceInfo(undefined)
        }
    },[props.branchPerformanceInfo])
    useEffect(() => {
        if (props.branchPerformanceInfoCollectionError) {
            showNotifications('error', props.branchPerformanceInfoCollectionError)
        }
    }, [props.branchPerformanceInfoCollectionError])

    // Low performing borrowers
    useEffect(() => {
        if (props.lowPerformingBorrowers) {
            setLowPerformingBorrowersInfo(props.lowPerformingBorrowers)
        } else {
            setLowPerformingBorrowersInfo(undefined)
        }
    },[props.lowPerformingBorrowers])
    useEffect(() => {
        if (props.lowPerformingBorrowersCollectionError) {
            showNotifications('error', props.lowPerformingBorrowersCollectionError)
        }
    }, [props.lowPerformingBorrowersCollectionError])

    // Activity section
    useEffect(() => {
        if (props.activeBatteryCounts) {
            setBatteryActivitiesTrend(props.activeBatteryCounts)
        } else {
            setBatteryActivitiesTrend(undefined)
        }
    },[props.activeBatteryCounts])
    useEffect(() => {
        if (props.activeBatteryCountsCollectionError) {
            showNotifications('error', props.activeBatteryCountsCollectionError)
        }
    }, [props.activeBatteryCountsCollectionError])

    useEffect(() => {
        if (props.inactiveBatteriesInfo) {
            setInactiveBatteries(props.inactiveBatteriesInfo)
        } else {
            setInactiveBatteries(undefined)
        }
    },[props.inactiveBatteriesInfo])
    useEffect(() => {
        if (props.errorMessageForInactiveBatteryInfoCollection) {
            showNotifications('error', props.errorMessageForInactiveBatteryInfoCollection)
        }
    }, [props.errorMessageForInactiveBatteryInfoCollection])
    // ------- End: Overview page data related -------- //

    const handleTabSelection = (event, newValue) => {
        setTabValue(newValue)
        if (newValue === 0) {
            // At a Glance
            COLLECT_AT_A_GLANCE_INFO(filterParams)
            COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE(filterParams)
        } else if (newValue === 1) {
            COLLECT_BRANCH_WISE_PERFORMANCE_INFO(filterParams)
        } else if (newValue === 2) {
            COLLECT_LOW_PERFORMING_BORROWERS(filterParams)
        } else {
            // Activity Trend
            COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND(filterParams)
            COLLECT_INACTIVE_BATTERIES(filterParams)
        }
    }


    return (
        <>
            {/* --- Tab headers --- */}
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Card sx={{ width: '100%', height: '100%'}}>
                        <CardContent sx={{ paddingBottom: '0px' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabSelection}
                                aria-label="overview"
                                classes={{ indicator: classes.indicator}}
                                variant={isTabletOrMobile? 'scrollable' : 'fullWidth'} // Allows the tabs to be scrollable on smaller screens and force them to take full width in larger screens
                                scrollButtons={isTabletOrMobile? 'auto' : false} // Allows the tabs to be scrollable on smaller screens
                                allowScrollButtonsMobile // Ensures that the scroll buttons are available on mobile devices
                            >
                                <Tab label="At a Glance" className={classes.tab} style={{ fontFamily: 'Open sans' }}/>
                                <Tab label="Branch-wise Performance" className={classes.tab} style={{ fontFamily: 'Open sans' }}/>
                                <Tab label="Borrower-wise Performance" className={classes.tab} style={{ fontFamily: 'Open sans' }}/>
                                <Tab label="Activity" className={classes.tab} style={{ fontFamily: 'Open sans' }}/>
                            </Tabs>
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* --- Filter form --- */}
            <div className={'row mt-2'}>
                <div className={'col-md-12'}>
                    <Card sx={{ width: '100%', height: '100%'}}>
                        <CardContent sx={{ paddingBottom: '0px' }}>
                            <div className={'row'}>
                                <div className={'col-md-12'}>
                                    <h6>Filter</h6>
                                </div>
                            </div>
                            {filterForm()}
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* --- Tab panels with content --- */}
            <div className={'row mt-2'}>
                <div className={'col-md-12'}>
                    <Card sx={{ width: '100%', height: '100%'}}>
                        <CardContent sx={{ paddingBottom: '0px' }}>
                            <TabPanel value={tabValue} index={0}>
                                <AtAGlanceOverview data={atAGlanceInformation}
                                                   dataForStaticCards={overviewDataFromAnotherSource}/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <BranchWisePerformanceOverview data={branchWisePerformanceInfo}/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <BorrowerWisePerformanceOverview data={lowPerformingBorrowersInfo}/>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <ActivityOverview activitiesTrend={batteryActivitiesTrend}
                                                  inactiveBatteries={inactiveBatteries}/>
                            </TabPanel>
                        </CardContent>
                    </Card>
                </div>
            </div>

            {overviewDateRangePopover()}
        </>
    );
}

UpdatedOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        branches: state.commonReducer.branches,
        collectingBranches: state.commonReducer.collectingBranches,
        garages: state.commonReducer.garages,
        collectingGarages: state.commonReducer.collectingGarages,
        errorMessageForGarageListCollection: state.commonReducer.errorMessageForGarageListCollection,
        zoneAreaInfo: state.commonReducer.zoneAreaInfo,
        zoneAreaInfoCollectionError: state.commonReducer.zoneAreaInfoCollectionError,
        collectingZoneAreaInfo: state.commonReducer.collectingZoneAreaInfo,
        inactiveBatteriesInfo: state.overviewReducer.inactiveBatteriesInfo,
        errorMessageForInactiveBatteryInfoCollection: state.overviewReducer.errorMessageForInactiveBatteryInfoCollection,
        collectingInactiveBatteriesInfo: state.overviewReducer.collectingInactiveBatteriesInfo,
        atAGlanceInfo: state.overviewReducer.atAGlanceInfo,
        atAGlanceInfoCollectionError: state.overviewReducer.atAGlanceInfoCollectionError,
        collectingAtAGlanceInfo: state.overviewReducer.collectingAtAGlanceInfo,
        summaryDataFromGarage: state.overviewReducer.summaryDataFromGarage,
        summaryDataCollectionErrorFromGarage: state.overviewReducer.summaryDataCollectionErrorFromGarage,
        collectingSummaryDataFromGarage: state.overviewReducer.collectingSummaryDataFromGarage,
        branchPerformanceInfo: state.overviewReducer.branchPerformanceInfo,
        branchPerformanceInfoCollectionError: state.overviewReducer.branchPerformanceInfoCollectionError,
        collectingBranchPerformanceInfo: state.overviewReducer.collectingBranchPerformanceInfo,
        lowPerformingBorrowers: state.overviewReducer.lowPerformingBorrowers,
        lowPerformingBorrowersCollectionError: state.overviewReducer.lowPerformingBorrowersCollectionError,
        collectingLowPerformingBorrowers: state.overviewReducer.collectingLowPerformingBorrowers,
        activeBatteryCounts: state.overviewReducer.activeBatteryCounts,
        activeBatteryCountsCollectionError: state.overviewReducer.activeBatteryCountsCollectionError,
        collectingActiveBatteryCounts: state.overviewReducer.collectingActiveBatteryCounts
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(UpdatedOverview))
