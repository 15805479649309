import React, {useEffect, useState, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {useMediaQuery} from "react-responsive";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Plot from "react-plotly.js";
import {DataTable} from "../../../../components";
import {DataTableContainerForLoanDetails} from "../../../contractManagement/utils";
import Skeleton from "@mui/material/Skeleton";


const BranchWisePerformanceOverview = ({data, ...props}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    // State variables for Graph
    const [yAxisForPaidInfo, setYAxisForPaidInfo] = useState(undefined) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForPaidInfo, setXAxisForPaidInfo] = useState(undefined) // Sample data format: [18, 25, 89, 38]
    const [textLabelForPaidInfo, setTextLabelForPaidInfo] = useState(undefined) // Sample data format: ['18%', '25%', '89%', '38%']
    const [yAxisForOverdueInfo, setYAxisForOverdueInfo] = useState(undefined) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForOverdueInfo, setXAxisForOverdueInfo] = useState(undefined) // Sample data format: [25, 20, 35, 18]
    const [textLabelForOverdueInfo, setTextLabelForOverdueInfo] = useState(undefined) // Sample data format: ['25%', '20%', '35%', '18%']

    const [yAxisForInvestmentInfo, setYAxisForInvestmentInfo] = useState(undefined) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForInvestmentInfo, setXAxisForInvestmentInfo] = useState(undefined) // Sample data format: [37499, 65000, 120000, 150000]
    const [textLabelForInvestmentInfo, setTextLabelForInvestmentInfo] = useState(undefined) // Sample data format: ['BDT 37,499', 'BDT 65,000', 'BDT 1,20,000', 'BDT 1,50,000']
    const [yAxisForPortfolioAtRiskInfo, setYAxisPortfolioAtRiskInfo] = useState(undefined) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForPortfolioAtRiskInfo, setXAxisForPortfolioAtRiskInfo] = useState(undefined) // Sample data format: [35000, 20000, 28000, 20000]
    const [textLabelForPortfolioAtRiskInfo, setTextLabelForPortfolioAtRiskInfo] = useState(undefined) // Sample data format: ['BDT 35,000', 'BDT 20,000', 'BDT 28,000', 'BDT 20,000']

    // Horizontal bar chart styling related
    const layout = {
        xaxis: {
            showgrid: false,
            showline: false,
            zeroline: false
        },
        yaxis: {
            showgrid: false,
            showline: false,
            zeroline: false,
            automargin: true
        },
        legend: {
            x: 0, // Position at the left
            y: 1.1, // Position above the chart
            xanchor: 'left', // Anchor the legend's x position to the left
            yanchor: 'bottom', // Anchor the legend's y position to the bottom
            orientation: 'h', // Orient the legend horizontally,
            traceorder: 'normal' // Ensure the legend items are displayed in the order they appear in the data array
        },
        autosize: true, // Ensures the chart adjusts its size dynamically
        hovermode: 'closest'
    }
    // Static data for the chart
    const paymentRelatedData = [
        {
            y: yAxisForPaidInfo,
            x: xAxisForPaidInfo,
            name: 'Paid',
            type: 'bar',
            orientation: 'h',
            text: textLabelForPaidInfo,    // Text labels for the bars
            textposition: 'auto',  // Let Plotly automatically determine the best position
            cliponaxis: false,      // Prevent text from being clipped
            marker: {
                color: '#0B6E4F'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>Paid:</b> %{x}<br><extra></extra>' // Custom tooltip content
        },
        {
            y: yAxisForOverdueInfo,
            x: xAxisForOverdueInfo,
            name: 'Overdue',
            type: 'bar',
            orientation: 'h',
            text: textLabelForOverdueInfo,    // Text labels for the bars
            textposition: 'auto',  // Let Plotly automatically determine the best position
            cliponaxis: false,      // Prevent text from being clipped
            marker: {
                color: '#BCBCCD'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>Overdue:</b> %{x}<br><extra></extra>' // Custom tooltip content
        }
    ]
    const determineTextPositionForOrangeBar = (dataSet) => {
        return dataSet.map((value) => {
            // Formula: highest value of dataset1 and dataset2 / highest number of data within two dataset
            // It helps to determine how many value will be printed at x-axis. We will show text outside if the value is within 0 and very next value
            return value >= (150000/4)? 'inside':'outside'
        })
    }
    // Investment bar style
    const textPositionsForOrangeBar = determineTextPositionForOrangeBar([37499, 65000, 120000, 150000])
    const determineColorsForOrangeBar = (textPositions) => {
        return textPositions.map((position) => {
            return position === 'inside'? 'white':'inherit'
        })
    }
    const textColorsForOrangeBar = determineColorsForOrangeBar(textPositionsForOrangeBar)
    // Investment data
    const investmentRelatedData = [
        {
            y: yAxisForInvestmentInfo,
            x: xAxisForInvestmentInfo,
            name: 'Total Investment',
            type: 'bar',
            orientation: 'h',
            text: textLabelForInvestmentInfo,    // Text labels for the bars
            textposition: textPositionsForOrangeBar,
            cliponaxis: false,  // Prevent text from being clipped
            marker: {
                color: '#F18D00'
            },
            textfont: {
                color: textColorsForOrangeBar // Set the text color to white
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>Investment:</b> %{x}<br><extra></extra>' // Custom tooltip content
        },
        {
            y: yAxisForPortfolioAtRiskInfo,
            x: xAxisForPortfolioAtRiskInfo,
            name: 'Total Portfolio at Risk',
            type: 'bar',
            orientation: 'h',
            text: textLabelForPortfolioAtRiskInfo,    // Text labels for the bars
            textposition: 'auto',  // Let Plotly automatically determine the best position
            cliponaxis: false,      // Prevent text from being clipped
            marker: {
                color: '#BCBCCD'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>At risk:</b> %{x}<br><extra></extra>' // Custom tooltip content
        }
    ]

    // Columns for the investment details table
    const investmentDetailsTableColumns = [
        {
            field: 'branch_name',
            title: "Branches",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span>{rowData.branch_name}</span><br/>
                    <span className={'small'}>{rowData.financier_name}</span>
                </>
            }
        },
        {
            field: 'total_batteries',
            title: "Total Batteries",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.total_batteries}</strong></h6>
                </>
            }
        },
        {
            field: 'investment_amount',
            title: "Investment",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.investment_amount}</strong></h6>
                </>
            }
        },
        {
            field: 'collection',
            title: "Collection",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.collection}</strong></h6>
                    <span>{rowData.collection_percentage}</span>
                </>
            }
        },
        {
            field: 'total_overdue',
            title: "Total Overdue",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={'text-danger'}><strong>{rowData.total_overdue}</strong></h6>
                </>
            }
        },
        {
            field: 'risk_amount',
            title: "Portfolio At Risk",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={'text-danger'}><strong>{rowData.risk_amount}</strong></h6>
                    <span>{rowData.risk_percentage}</span>
                </>
            }
        }
    ]
    const [investmentDetails, setInvestmentDetails] = useState(undefined)

    useEffect(() => {
        if (data) {
            if (data["branch_wise_summary"]) {
                let branchData = data["branch_wise_summary"]

                // --------------- Begin: Constructing data for graphs and table ---------------- //
                let yAxisForGraphs = []

                // "Collection vs Overdue" graph
                let xAxisForPaid = []
                let textLabelForPaid = []
                let xAxisForOverdue = []
                let textLabelForOverdue = []

                // "Investment vs Portfolio at Risk" graph
                let xAxisForInvestment = []
                let textLabelForInvestment = []
                let xAxisForRiskPortfolio = []
                let textLabelForRiskPortfolio = []

                // Branch wise investment details - table
                let branchInvestmentDetails = []

                for (let i=0; i< branchData.length; i++) {
                    let branchName = Object.keys(branchData[i])[0]
                    let paidPercentage = branchData[i][branchName]["total_paid_percentage"]? parseFloat(branchData[i][branchName]["total_paid_percentage"].toFixed(2)):0
                    let overduePercentage = branchData[i][branchName]["total_overdue_percentage"]? parseFloat(branchData[i][branchName]["total_overdue_percentage"].toFixed(2)):0
                    let totalInvestment = branchData[i][branchName]["total_investment"]? parseFloat(branchData[i][branchName]["total_investment"].toFixed(2)):0
                    let totalRiskPortfolio = branchData[i][branchName]["portfolio_at_risk"]? parseFloat(branchData[i][branchName]["portfolio_at_risk"].toFixed(2)):0
                    yAxisForGraphs.push(branchName)
                    // Collection vs Overdue - graph
                    xAxisForPaid.push(paidPercentage)
                    textLabelForPaid.push(paidPercentage + "%")
                    xAxisForOverdue.push(overduePercentage)
                    textLabelForOverdue.push(overduePercentage + "%")
                    // Investment vs Portfolio at Risk - graph
                    xAxisForInvestment.push(totalInvestment)
                    textLabelForInvestment.push('BDT ' + new Intl.NumberFormat('en-IN').format(totalInvestment))
                    xAxisForRiskPortfolio.push(totalRiskPortfolio)
                    textLabelForRiskPortfolio.push('BDT ' + new Intl.NumberFormat('en-IN').format(totalRiskPortfolio))
                    // Branch wise investment details - table
                    branchInvestmentDetails.push({
                        'branch_name': branchName,
                        'financier_name': branchData[i][branchName]["Organization"]? branchData[i][branchName]["Organization"]:'',
                        'total_batteries': branchData[i][branchName]["number_of_contracts"]? branchData[i][branchName]["number_of_contracts"]:'',
                        'investment_amount': 'BDT ' + new Intl.NumberFormat('en-IN').format(totalInvestment),
                        'collection': 'BDT ' + new Intl.NumberFormat('en-IN').format(branchData[i][branchName]["total_paid"]),
                        'collection_percentage': paidPercentage? paidPercentage + "%":'',
                        'total_overdue': 'BDT ' + new Intl.NumberFormat('en-IN').format(branchData[i][branchName]["total_overdue"]),
                        'risk_amount': 'BDT ' + new Intl.NumberFormat('en-IN').format(totalRiskPortfolio),
                        'risk_percentage': overduePercentage? overduePercentage + "%":''
                    })
                }

                // Collection vs Overdue - graph
                setYAxisForPaidInfo(yAxisForGraphs)
                setXAxisForPaidInfo(xAxisForPaid)
                setTextLabelForPaidInfo(textLabelForPaid)
                setYAxisForOverdueInfo(yAxisForGraphs)
                setXAxisForOverdueInfo(xAxisForOverdue)
                setTextLabelForOverdueInfo(textLabelForOverdue)

                // Investment vs Portfolio at Risk - graph
                setYAxisForInvestmentInfo(yAxisForGraphs)
                setXAxisForInvestmentInfo(xAxisForInvestment)
                setTextLabelForInvestmentInfo(textLabelForInvestment)
                setYAxisPortfolioAtRiskInfo(yAxisForGraphs)
                setXAxisForPortfolioAtRiskInfo(xAxisForRiskPortfolio)
                setTextLabelForPortfolioAtRiskInfo(textLabelForRiskPortfolio)

                // Branch wise investment details - table
                setInvestmentDetails(branchInvestmentDetails)
                // --------------- End: Constructing data for graphs and table ---------------- //
            } else {
                // Collection vs Overdue - graph
                setYAxisForPaidInfo([])
                setXAxisForPaidInfo([])
                setTextLabelForPaidInfo([])
                setYAxisForOverdueInfo([])
                setXAxisForOverdueInfo([])
                setTextLabelForOverdueInfo([])

                // Investment vs Portfolio at Risk - graph
                setYAxisForInvestmentInfo([])
                setXAxisForInvestmentInfo([])
                setTextLabelForInvestmentInfo([])
                setYAxisPortfolioAtRiskInfo([])
                setXAxisForPortfolioAtRiskInfo([])
                setTextLabelForPortfolioAtRiskInfo([])

                // Branch wise investment details - table
                setInvestmentDetails([])
            }
        }
    },[data])

    const graphicalCard = (title, data, layout) => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        {title}
                    </Typography>
                    <Plot
                        data={data}
                        useResizeHandler={true} // This property makes the Plotly chart responsive to window resizing.
                        style={{width: '100%', height: '100%'}} // setting height to 100% has some effects that can't be noticed instantly
                        layout={{...layout}}
                        config={isTabletOrMobile? {
                            displayModeBar: false  // Make the mode bar (download, zoom etc. icons) inaccessible in mobile view, in desktop view it becomes visible when user scrolls over the graph
                        }:{}}
                    />
                </CardContent>
            </Card>
        </>
    }

    const graphicalCardSkeleton = (title) => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        {title}
                    </Typography>
                    <Skeleton variant="rect" sx={{ marginTop: '10px', width: '100%' }} height={440}/>
                </CardContent>
            </Card>
        </>
    }

    const investmentDetailsCard = () => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        Branch-wise Investment Details
                    </Typography>
                    <div className={'mt-8'}>
                        {props.collectingBranchPerformanceInfo || !investmentDetails? <>
                            <Skeleton variant="rect" sx={{ width: '100%' }} height={369}/>
                        </>:<>
                            <DataTableContainerForLoanDetails>
                                <DataTable
                                    language={props.language}
                                    noDataAvailableMessageInEnglish={"No investment details information available"}
                                    columns={investmentDetailsTableColumns}
                                    data={investmentDetails}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    pagination={true}
                                    pageSize={5}
                                />
                            </DataTableContainerForLoanDetails>
                        </>}
                    </div>
                </CardContent>
            </Card>
        </>
    }

    return (
        <>
            <div className={'row'}>
                <div className={isTabletOrMobile? 'col-md-6':'col-md-6 pl-0'}>
                    {props.collectingBranchPerformanceInfo ||
                    !(xAxisForPaidInfo && yAxisForPaidInfo && textLabelForPaidInfo && xAxisForOverdueInfo &&
                        yAxisForOverdueInfo && textLabelForOverdueInfo)? <>
                        {graphicalCardSkeleton('Collection vs Overdue')}
                    </>:<>
                        {graphicalCard('Collection vs Overdue', paymentRelatedData, {
                            ...layout,
                            xaxis: {
                                ticksuffix: '%', // Add suffix to text
                            }
                        })}
                    </>}
                </div>
                <div className={isTabletOrMobile? 'col-md-6 mt-2':'col-md-6'}>
                    {props.collectingBranchPerformanceInfo ||
                    !(xAxisForInvestmentInfo && yAxisForInvestmentInfo && textLabelForInvestmentInfo &&
                        xAxisForPortfolioAtRiskInfo && yAxisForPortfolioAtRiskInfo && textLabelForPortfolioAtRiskInfo)? <>
                        {graphicalCardSkeleton('Investment vs Portfolio at Risk')}
                    </>:<>
                        {graphicalCard('Investment vs Portfolio at Risk', investmentRelatedData, {
                            ...layout,
                            xaxis: {
                                tickprefix: 'BDT ', // Add prefix to text
                            }
                        })}
                    </>}
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className={isTabletOrMobile? 'col-md-12':'col-md-12 pl-0'}>
                    {investmentDetailsCard()}
                </div>
            </div>
        </>
    );
}

BranchWisePerformanceOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBranchPerformanceInfo: state.overviewReducer.collectingBranchPerformanceInfo
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(BranchWisePerformanceOverview))
